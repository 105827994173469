// Libs
import React, { createContext, useContext, useEffect, useState } from 'react';
import cookie from 'react-cookies';
import { useAuth0 } from '@auth0/auth0-react';
import { useQuery, useQueryClient } from 'react-query';
import dateFormat from 'dateformat';

// APIs
import apiFetch, { api } from './api/fetcher';
import { QUERY_KEYS } from './api/fetchHooks/QUERY_KEYS';
import { API_URL } from './api/apiURLs';
import { useFetchCamerasByLocation } from './api/fetchHooks/camera';
import { useFetchPagesActions } from './api/fetchHooks/roles';
import { useProxyQuery } from './api/fetchHooks/useProxyQuery';
import { useFetchLocationPipelineStatus } from './api/fetchHooks/locations';

// Constants
import { DEFAULT_OBJECTIVES_SELECTS, COOKIE_COMPANY_ID } from './constants';
import { PipelineSign } from './components/pipelineSign';
import { COLLAPSE_ACTIONS } from './components/floorPlan/constants';
import { CAMERA_VIEW_OPTIONS } from './pages/overlayTools/cameraViewAssist/constants';

export const AppContext = createContext();

export const AppProvider = ({ children, ...rest }) => {
  const queryClient = useQueryClient();

  const { getIdTokenClaims, user } = useAuth0();
  const [auth0AccessToken, setAuth0AccessToken] = useState();
  const [jwtToken, setJwtToken] = useState();
  const [userInfo, setUserInfo] = useState();
  const [termsStatus, setTermsStatus] = useState(false);
  const [isInternalRole, setInternalRole] = useState(false);
  const [company, setCompany] = useState();

  const [vmsDemoSearchState, setVmsDemoSearchState] = useState(0);

  /* Transporting variables or objects from to another component  */
  const [swapPool, setSwapPool] = useState(null);

  /* Location what was selected in the header dropdown */
  const [globalLocation, setGlobalLocation] = useState();
  /* All locations what user has access to*/
  const [userLocations, setUserLocations] = useState();
  /* State to manage camera selection to*/
  const [cameraOptions, setCameraOptions] = useState();
  /* State to manage camera selection to*/
  const [locationOptions, setLocationOptions] = useState();

  /* Camera what was selected in the header dropdown */
  const [globalCamera, setGlobalCamera] = useState();
  const [globalCameraPipelineStatus, setGlobalCameraPipelineStatus] =
    useState();
  /* All cameras what user has access to*/
  const [userCameras, setUserCameras] = useState();
  /* Open up a dialog when camera selection is wrong based on location to*/
  const [wrongCameraDialog, setWrongCameraDialog] = useState(false);
  // For getting page id according to the page name and vise versa
  const [pageIdMap, setPageIdMap] = useState();
  // For getting action id according to the action name and vise versa
  const [actionIdMap, setActionIdMap] = useState();
  const [pagePermission, setPagePermission] = useState({});

  /* Camera Objectives selects values,
   * they come from Template Management
   * and use in the Camera Objectives page */
  const [cameraObjectivesSelects, setCameraObjectivesSelects] = useState(
    DEFAULT_OBJECTIVES_SELECTS
  );

  //SIDEBAR
  const [isSidebarOpened, setIsSidebarOpened] = useState(true);

  //NOTIFICATION
  const [activeNotify, setActiveNotify] = useState(null);

  //FLOORPLAN
  const [selectedComponent, setSelectedComponent] = useState(null);
  const [activeButton, setActiveButton] = useState('INT');
  const [cameraView, setCameraView] = useState(CAMERA_VIEW_OPTIONS.INTERIOR);
  const [userAction, setUserAction] = useState(
    COLLAPSE_ACTIONS.USER_NOT_INTERACTED
  );
  const [isCollapsed, setIsCollapsed] = useState(false);

  const token = window.Cypress
    ? window.localStorage.getItem('id_token')
    : auth0AccessToken;

  const { data: pipelineStatusData } = useFetchLocationPipelineStatus(
    globalLocation?.id,
    { enabled: !!globalLocation?.id && isInternalRole }
  );

  const findPipelineStatusForGivenCameraName = cameraName => {
    let status = null;
    pipelineStatusData?.data.forEach(element => {
      if (element.pipeline?.name.includes(cameraName))
        status = element.pipeline?.status;
    });
    let statusSign = null;
    if (status === 'healthy')
      statusSign = (
        <PipelineSign id='zegrmirhvo' variant='healthy' size='small'>
          HEALTHY
        </PipelineSign>
      );
    else if (status === 'Not Running')
      statusSign = (
        <PipelineSign id='znyhymoglg' variant='unhealthy' size='small'>
          NOT RUNNING
        </PipelineSign>
      );
    else if (status === 'not setup')
      statusSign = (
        <PipelineSign id='uiydxqxujl' variant='notsetup' size='small'>
          NOT SETUP
        </PipelineSign>
      );
    else if (status === 'unhealthy')
      statusSign = (
        <PipelineSign
          id='ngbnpanlgd'
          title='Camera Pipeline Status'
          variant='unhealthy'
          size='small'
        >
          UNHEALTHY
        </PipelineSign>
      );
    else if (status === 'restarting')
      // Live image not seen
      statusSign = (
        <PipelineSign
          id='ulbhjkeygu'
          title='Camera Pipeline Status'
          variant='unhealthy'
          size='small'
        >
          RESTARTING
        </PipelineSign>
      );
    else {
      statusSign = (
        <PipelineSign
          id='jtbegikfzb'
          title='Camera Pipeline Status'
          variant='unhealthy'
          size='small'
        >
          UNKNOWN
        </PipelineSign>
      );
    }

    return { sign: statusSign, status };
  };

  // Delete companyId from cookie on reload page
  useEffect(() => {
    cookie.remove(COOKIE_COMPANY_ID, { path: '/' });
  }, []);

  useEffect(() => {
    if (company !== undefined) {
      cookie.save(COOKIE_COMPANY_ID, company.id, { path: '/' });
      queryClient.invalidateQueries(QUERY_KEYS.USER_INFO);
      queryClient.invalidateQueries(QUERY_KEYS.GLOBAL_LOCATION);
      queryClient.invalidateQueries(QUERY_KEYS.COMPANY_ROLES);
      queryClient.invalidateQueries(QUERY_KEYS.SERVERS);
      queryClient.invalidateQueries(QUERY_KEYS.PLANS);
    }
  }, [company]);

  useEffect(() => {
    if (globalCamera) {
      const cameraPipelineStatus = findPipelineStatusForGivenCameraName(
        globalCamera.display_name.split(' ')[1]
      );
      setGlobalCameraPipelineStatus(cameraPipelineStatus);
    }
  }, [pipelineStatusData, globalCamera]);

  useEffect(() => {
    if (userInfo) {
      const get_role = userInfo?.roles;
      const role_string = JSON.stringify(get_role);
      const isInternalRole =
        role_string.includes('RAI_ADMIN') || role_string.includes('Support');

      setInternalRole(isInternalRole);

      const pagesActions = userInfo.pages.reduce((acc, page) => {
        acc[page.name] = page.actions;
        return acc;
      }, {});
      setPagePermission(pagesActions);
    }
  }, [userInfo]);

  useEffect(() => {
    getIdTokenClaims().then(data => {
      setAuth0AccessToken(data?.__raw);
    });
  }, [user?.email]);
  const date = new Date();
  const client_datetime = dateFormat(date, 'yyyy-mm-dd HH:MM:ss');

  const {
    data,
    isLoading: isUserLoginLoading,
    refetch: refetchUserData,
  } = useQuery(
    [QUERY_KEYS.USER_INFO, token],
    () =>
      apiFetch(API_URL.getUserInit(), {
        id_token: token,
        client_ts: client_datetime,
      }),
    {
      enabled: !!token && user?.email_verified,
    }
  );

  useEffect(() => {
    const userJWT = data?.jwt_token;
    if (userJWT) {
      api.defaults.headers = {
        Authorization: `Bearer ${userJWT}`,
      };
      queryClient.setDefaultOptions({
        queries: {
          refetchOnWindowFocus: false,
          retry: false,
          enabled: !!userJWT,
        },
      });
      setJwtToken(userJWT);
    }

    if (data) {
      cookie.save(COOKIE_COMPANY_ID, data.company.id, { path: '/' });
      setUserInfo({ ...data });
    }

    if (
      data?.integrator_terms_confirm_needed !== undefined &&
      data.integrator_terms_confirm_needed
    ) {
      setTermsStatus(true);
    }
  }, [data]);

  const {
    data: fetchedUserLocations,
    isFetching: isUserLocationsFetching,
    isLoading: isUserLocationsLoading,
    refetch: refetchUserLocations,
  } = useProxyQuery([QUERY_KEYS.GLOBAL_LOCATION, auth0AccessToken], () =>
    apiFetch(API_URL.getAllUserLocations(), {})
  );

  useEffect(() => {
    if (!isUserLocationsFetching || !isUserLocationsLoading)
      setUserLocations(fetchedUserLocations);
  }, [fetchedUserLocations]);

  const {
    data: fetchedUserCameras,
    refetch: refetchUserCameras,
    isLoading: isUserCamerasLoading,
    isFetching: isUserCamerasFetching,
  } = useFetchCamerasByLocation(globalLocation?.id, {
    enabled: Boolean(globalLocation?.id),
  });

  useEffect(() => {
    if ((!isPageActionsLoading || !isUserCamerasFetching) && fetchedUserCameras)
      setUserCameras(fetchedUserCameras);
    if (isUserCamerasFetching) setCameraOptions([]);
  }, [fetchedUserCameras]);

  useEffect(() => {
    const isCameraExist = userCameras?.some(
      camera => camera?.id === globalCamera?.id
    );
    if (!isCameraExist) {
      setGlobalCamera(null);
    }
  }, [globalLocation]);

  const { data: pagesActions, loading: isPageActionsLoading } =
    useFetchPagesActions();

  useEffect(() => {
    if (pagesActions) {
      const { pages, actions } = pagesActions;
      const pageMap = pages.reduce((acc, page) => {
        acc[page.id] = page.name;
        acc[page.name] = page.id;
        return acc;
      }, {});
      const actionMap = actions.reduce((acc, action) => {
        acc[action.id] = action.name;
        acc[action.name] = action.id;
        return acc;
      }, {});

      setPageIdMap(pageMap);
      setActionIdMap(actionMap);
    }
  }, [pagesActions]);

  return (
    <AppContext.Provider
      id='gkbejbnshl'
      value={{
        //USER
        userInfo,
        userPages: userInfo?.pages || [],
        refetchUserData,
        jwtToken,
        auth0AccessToken,
        termsStatus,
        setCompany,
        company,
        //LOCATION
        globalLocation,
        setGlobalLocation,
        userLocations,
        refetchUserLocations,
        isUserLocationsFetching,
        isUserLocationsLoading,
        //CAMERAS
        globalCamera,
        setGlobalCamera,
        globalCameraPipelineStatus,
        userCameras,
        isUserCamerasLoading,
        isUserCamerasFetching,
        isUserLoginLoading,
        refetchUserCameras,
        setCameraOptions,
        cameraOptions,
        setWrongCameraDialog,
        wrongCameraDialog,
        //SIDEBAR
        isSidebarOpened,
        setIsSidebarOpened,
        //CAMERA OBJECTIVES SELECTS VALUES
        cameraObjectivesSelects,
        setCameraObjectivesSelects,
        //REST
        swapPool,
        setSwapPool,
        pagesActions,
        isPageActionsLoading,
        pageIdMap,
        actionIdMap,
        pagePermission,
        activeNotify,
        setActiveNotify,
        // FLOORPLAN CAMERA SELECTION
        setSelectedComponent,
        selectedComponent,
        setActiveButton,
        activeButton,
        setCameraView,
        cameraView,
        setLocationOptions,
        locationOptions,
        userAction,
        setUserAction,
        setIsCollapsed,
        isCollapsed,
        vmsDemoSearchState,
        setVmsDemoSearchState,
        ...rest,
      }}
    >
      {children}
    </AppContext.Provider>
  );
};

export function useAppContext() {
  return useContext(AppContext);
}
