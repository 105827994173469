// Libs
import React from 'react';
import { useHistory } from 'react-router-dom';
// eslint-disable-next-line import/no-cycle
import { useReload } from '../reloadModal';
import { Icon } from '../iconComponent/Icon';

const Logo = () => {
  const history = useHistory();
  const { handleUnsavedClose } = useReload();

  const handleClick = () => {
    handleUnsavedClose(() => {
      history.push('/');
    });
  };

  return (
    <Icon
      id='zrobrrjcvo'
      size='logo'
      iconName='LogoIcon'
      onClick={handleClick}
    />
  );
};

export default Logo;
