/* eslint-disable object-shorthand */
/*eslint-disable jsx-a11y/no-static-element-interactions*/
import React from 'react';
import Canvas from './Canvas';
import ZoneContext from './ZoneContext';

const ZoneStage = ({
  stageWidth,
  stageHeight,
  polygons,
  setPolygons,
  isPolygonDrawing,
  setIsPolygonDrawing,
  sidesPerPolygonLimit,
  polygonsLimit,
  setAreActionsDisabled,
  automatedAoi,
  unmutuablePolygons,
  opacity,
  setWasPointMoved,
  isUserActionPermitted,
  transform,
  imgRealWidth,
  imgRealHeigth,
}) => {
  return (
    // see issue https://github.com/konvajs/react-konva/issues/188
    <ZoneContext.Provider>
      <Canvas
        stageWidth={stageWidth}
        stageHeight={stageHeight}
        polygons={polygons}
        setPolygons={setPolygons}
        isPolygonDrawing={isPolygonDrawing}
        setIsPolygonDrawing={setIsPolygonDrawing}
        sidesPerPolygonLimit={sidesPerPolygonLimit}
        polygonsLimit={polygonsLimit}
        setAreActionsDisabled={setAreActionsDisabled}
        automatedAoi={automatedAoi}
        unmutuablePolygons={unmutuablePolygons}
        opacity={opacity}
        setWasPointMoved={setWasPointMoved}
        isUserActionPermitted={isUserActionPermitted}
        transform={transform}
        imgRealWidth={imgRealWidth}
        imgRealHeigth={imgRealHeigth}
      />
    </ZoneContext.Provider>
  );
};

export default ZoneStage;
