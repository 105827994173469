import styled from 'styled-components';
import { COLORS } from '../colors';

export const SearchBarContainer = styled.div`
  margin-bottom: 16px;
  margin-top: 2px;
`;

export const SearchInputWrapper = styled.div`
  position: relative;
  width: 400px;
`;

export const SearchIconWrapper = styled.div`
  position: absolute;
  top: 50%;
  transform: translate(0, -50%);
  left: var(--spacing-3xs);
  pointer-events: none;
  z-index: 1;
`;

export const ClearButton = styled.div`
  position: absolute;
  top: 50%;
  transform: translate(0, -50%);
  right: var(--spacing-2xs);
  &:hover {
    cursor: pointer;
  }
`;

export const SearchButton = styled.button`
  width: auto;
  padding: 11px 18px;
  font-size: 16px;
  line-height: 24px;
  font-style: normal;
  font-weight: 600;
  border-radius: 8px;
  border: none;
  color: ${COLORS.White};
  background-color: ${props => (props.disabled ? '#DFE1E6' : COLORS.MainBlue)};

  cursor: ${props => (props.disabled ? 'initial' : 'pointer')};

  &:hover {
    box-shadow: ${props =>
      props.disabled ? 'none' : '0 4px 6px rgba(18, 104, 251, 0.2)'};
  }
`;
