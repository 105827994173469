// Libs
import React, { lazy, Suspense } from 'react';
import { Switch, Route, Redirect, useRouteMatch } from 'react-router-dom';
import { useAuth0 } from '@auth0/auth0-react';
// Routes
import { routeService } from './routes/routesList';
// Components
import ProtectedRoute from './components/protectedRoute/ProtectedRoute';
import Toaster from './components/notification/Notification';
import Loader from './components/Loader/Loader';
import Header from './components/header/Header';
// Styles
import './styles/variables.css';
import './styles/reset.css';
import './styles/animations.css';
import styles from './App.module.css';

const Home = lazy(() => import('./pages/home'));
const Admin = lazy(() => import('./routes'));
const Welcome = lazy(() => import('./pages/welcome/Welcome'));
const NoEmailVerified = lazy(() => import('./pages/noEmailVerified'));
const NoAccess = lazy(() => import('./pages/noAccess'));
const TermsAndConditions = lazy(() => import('./pages/terms'));

const App = () => {
  const {
    list: { home, admin, no_email_verified, no_access, terms },
    getRelativePath,
    getPath,
  } = routeService;

  const { isAuthenticated, isLoading } = useAuth0();

  const { path } = useRouteMatch();

  const homeRoute = getRelativePath({ path, item: home });
  const adminRoute = getRelativePath({ path, item: admin });
  const noAccessRoute = getPath({ item: no_access });
  const noEmailVerifiedRoute = getPath({ item: no_email_verified });
  const termsAndConditionsRoute = getPath({ item: terms });

  if (isLoading) {
    return <Loader id='armkjzasay' alt='Loading...' />;
  }

  return (
    <div className={`${styles.app}`}>
      <div className={styles.Content}>
        {isAuthenticated && <Header id='zakxjdbqen' />}
        <Switch id='msfqeimbda'>
          {isAuthenticated && (
            <Redirect id='ckfphrykyq' exact from='/' to={homeRoute} />
          )}
          <Suspense
            id='vhdgfjzjth'
            fallback={<Loader id='mwpvrgdyku' alt='Loading...' />}
          >
            <Route id='kafojpsuwy' exact path='/' component={Welcome} />
            <ProtectedRoute
              id='idohjwnzqd'
              exact
              path={homeRoute}
              component={Home}
            />
            <ProtectedRoute
              id='uoisishixn'
              path={adminRoute}
              component={Admin}
            />
            <Route
              id='kbnrccixdk'
              path={noEmailVerifiedRoute}
              component={NoEmailVerified}
            />
            <Route id='npschebdzi' path={noAccessRoute} component={NoAccess} />
            <Route
              id='ewdppkeced'
              path={termsAndConditionsRoute}
              component={TermsAndConditions}
            />
          </Suspense>
        </Switch>
      </div>
      <Toaster id='eqkfzjeuvd' />
    </div>
  );
};

export default App;
