// Libs
import React from 'react';
// Routes
import { routeService } from '../routes/routesList';
// Components
import { Icon } from '../components/iconComponent/Icon';
// eslint-disable-next-line import/no-cycle
import { COLORS } from '../components';

const { list } = routeService;

export const getPageIcon = (condition, size, color) => {
  switch (condition) {
    case list.locations_and_cameras.name:
      return (
        <Icon
          id='ilzapgpanj'
          size={size}
          iconName='ListIcon'
          fill={color}
          hoverColor={COLORS.MainBlue}
        />
      );
    case list?.overlay_tools?.name:
      return (
        <Icon
          id='cdxdsnupqm'
          size={size}
          iconName='CameraIcon'
          fill={color}
          hoverColor={COLORS.MainBlue}
        />
      );
    case list?.installation_management?.name:
      return (
        <Icon
          id='ejhrezyiyu'
          size={size}
          iconName='DataIcon'
          fill={color}
          hoverColor={COLORS.MainBlue}
        />
      );
    case list?.location_status?.name:
      return (
        <Icon
          id='jnygomfybz'
          size={size}
          iconName='LocationIcon'
          fill={color}
          hoverColor={COLORS.MainBlue}
        />
      );
    case list?.user_management?.name:
      return (
        <Icon
          id='gsritclyjl'
          size={size}
          iconName='UserSimpleIcon'
          fill={color}
          hoverColor={COLORS.MainBlue}
        />
      );
    case list?.clear_edge?.name:
      return (
        <Icon
          id='gsritclyjv'
          size={size}
          iconName='ClearEdgeIcon'
          fill={color}
          hoverColor={COLORS.MainBlue}
        />
      );
    case list?.vms_settings?.name:
      return (
        <Icon
          id='gsritclyjv'
          size={size}
          iconName='SettingsIcon'
          fill={color}
          hoverColor={COLORS.MainBlue}
        />
      );
    case list?.user_write_log?.name:
      return (
        <Icon
          id='xbigibexev'
          size={size}
          iconName='UserWriteLogIcon'
          fill={color}
          hoverColor={COLORS.MainBlue}
        />
      );
    case list?.template_management?.name:
      return (
        <Icon
          id='erqhjpuehc'
          size={size}
          iconName='TemplateSimpleIcon'
          fill={color}
          hoverColor={COLORS.MainBlue}
        />
      );
    case list?.help?.name:
      return (
        <Icon
          id='bkewxsgzby'
          size={size}
          iconName='NoticeIcon'
          fill={color}
          hoverColor={COLORS.MainBlue}
        />
      );
    case list?.version_control?.name:
      return (
        <Icon
          id='gpvuujhmty'
          size={size}
          iconName='VersionPageIcon'
          stroke={color}
          hoverColor={COLORS.MainBlue}
        />
      );
    default:
      break;
  }
};
