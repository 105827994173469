// Libs
import dateFormat from 'dateformat';
// APIs
import { QUERY_KEYS } from './QUERY_KEYS';
import apiFetch from '../fetcher';
import { API_URL } from '../apiURLs';
import { useProxyQuery } from './useProxyQuery';

export const useFetchAllLocations = (search, orderBy, options) => {
  const is_fetch = true;
  return useProxyQuery(
    [QUERY_KEYS.LOCATION],
    () => apiFetch(API_URL.getLocationsList({ search, orderBy }), { is_fetch }),
    options
  );
};

export const useFetchAllLocationEvents = options => {
  return useProxyQuery(
    [QUERY_KEYS.LOCATION_EVENTS],
    () => apiFetch(API_URL.getLocationEventsList(options)),
    options
  );
};

export const useFetchCompanyLocations = options => {
  return useProxyQuery(
    QUERY_KEYS.COMPANY_LOCATIONS,
    () => apiFetch(API_URL.getCompanyLocations()),
    options
  );
};

export const useFetchCompanyRoles = () => {
  return useProxyQuery(QUERY_KEYS.COMPANY_ROLES, () =>
    apiFetch(API_URL.getCompanyRoles())
  );
};

export const useFetchCompanyUsers = () => {
  return useProxyQuery(QUERY_KEYS.COMPANY_USERS, () =>
    apiFetch(API_URL.getCompanyUsers())
  );
};

export const useFetchLocationStatusData = (id, options) => {
  return useProxyQuery(
    [QUERY_KEYS.LOCATION_STATUS_DATA, id],
    () => apiFetch(API_URL.getStatusDataByLocation(id)),
    options
  );
};

export const useFetchLocationDeploymentStatusFlow = (id, options) => {
  const client_ts = dateFormat(new Date(), 'yyyy-mm-dd HH:MM:ss');
  const client_tzo = new Date().getTimezoneOffset();
  return useProxyQuery(
    [QUERY_KEYS.LOCATION_STATUS_FLOW, id],
    () =>
      apiFetch(API_URL.getLocationStatusFlow(id), {
        client_ts,
        client_tzo,
      }),
    options
  );
};

export const useFetchLocationInstallerRoles = () => {
  return useProxyQuery([QUERY_KEYS.LOCATIONS], () =>
    apiFetch(API_URL.getAllInstallerRole())
  );
};

export const useFetchStatusData = (id, options) => {
  return useProxyQuery(
    [QUERY_KEYS.STATUS_DATA, id],
    () => apiFetch(API_URL.getValidationsByLocation(id)),
    options
  );
};

export const useFetchAllUserLocations = options => {
  return useProxyQuery(
    [QUERY_KEYS.GLOBAL_LOCATION],
    () => apiFetch(API_URL.getAllUserLocations()),
    options
  );
};

export const useFetchPlans = options => {
  return useProxyQuery(
    [QUERY_KEYS.PLANS],
    () => apiFetch(API_URL.getPlans()),
    options
  );
};

export const useFetchLocationPipelineStatus = (id, options) => {
  const client_tzo = new Date().getTimezoneOffset();
  return useProxyQuery(
    [QUERY_KEYS.LOCATION_PIPELINE_STATUS, id],
    () => {
      return apiFetch(API_URL.getLocationPipelineStatus(id), {
        client_tzo,
      });
    },
    options
  );
};
