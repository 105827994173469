// Libs
import React from 'react';
import { useHistory } from 'react-router-dom';
// Context
import { useAppContext } from '../../../AppContext';
// Components
import { Dialog } from '../../dialogs';
import { Button } from '../../buttons';
import { Heading5 } from '../../fonts';

function WrongCameraDialog() {
  const { setWrongCameraDialog, wrongCameraDialog, setGlobalCamera } =
    useAppContext();
  const history = useHistory();

  const handleClose = () => {
    setWrongCameraDialog({
      isOpen: false,
      camera: '',
      tool: '',
      isOpenedFromFloorplan: false,
    });
  };
  const handleGoTo = () => {
    setGlobalCamera(null);
    history.push(wrongCameraDialog.switchTo);
    setWrongCameraDialog({
      isOpen: false,
      camera: '',
      tool: '',
      isOpenedFromFloorplan: false,
    });
  };
  const renderHeader = () => (
    <Heading5>
      {wrongCameraDialog.tool} is not applicable for {wrongCameraDialog.camera}
    </Heading5>
  );
  const renderFooter = () => (
    <>
      <Button
        size='small'
        onClick={() => {
          handleClose();
        }}
      >
        Okay
      </Button>
      {!wrongCameraDialog.isOpenedFromFloorplan ? (
        <Button
          size='small'
          variant='secondary'
          onClick={() => {
            handleGoTo();
          }}
        >
          Go to {wrongCameraDialog.tool}
        </Button>
      ) : (
        <></>
      )}
    </>
  );

  return (
    <Dialog
      size='large'
      isOpen={wrongCameraDialog.isOpen}
      onClose={handleClose}
      header={renderHeader()}
      footer={renderFooter()}
      zIndex='10000'
    />
  );
}

export default WrongCameraDialog;
