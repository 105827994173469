export const API_URL = {
  // Users
  getUserInit: () => `/users/init`,
  getUsers: ({ search = '' }) => `/users/list?search=${search}`,
  addUser: () => `/users/add`,
  updateUser: id => `/users/${id}/update`,
  deleteUser: id => `/users/${id}/delete`,
  deleteRole: id => `/roles/${id}/delete`,
  getTimezone: (city, state) =>
    `locations/timezone?city=${city}&state=${state}`,
  getLocationsList: ({ search = '', orderBy = '' }) =>
    `/locations/list?search=${search}&order_by=${orderBy}`,
  createAgreedTermsLog: () => `/users/terms-agreed`,
  // Locations
  createLocation: () => '/locations/create',
  updateLocation: id => `/locations/${id}/update`,
  deleteLocation: id => `/locations/${id}/delete`,
  getCompanyUsers: () => '/users/all',
  setCompany: id => `/users/set_company/${id}`,
  getLocationEventsList: id => `/locations/${id}/list_events`,
  createLocationEvent: () => '/locations/create_event',
  updateLocationEvent: location_event_id =>
    `/locations/${location_event_id}/update_event`,
  deleteLocationEvent: location_event_id =>
    `/locations/${location_event_id}/delete_event`,
  //Groups
  getGroupsList: ({ search = '' }) => `/groups/list?search=${search}`,
  createGroup: () => '/groups/create',
  updateGroup: id => `/groups/${id}/update`,
  deleteGroup: id => `/groups/${id}/delete`,
  //Fetch all user groups || locations
  getAllUserLocations: () => '/locations/all-user',
  // Fetches all company locations
  getCompanyLocations: () => '/locations/all',
  // Fetches all company groups
  getCompanyGroups: () => '/groups/all',
  // Fetches all company roles
  getCompanyRoles: () => '/roles/all',
  //PIPELINES
  getPipelineVersions: (search, timestamp_from, timestamp_to) =>
    `/versions/list?search=${search}&timestamp_from=${timestamp_from}&timestamp_to=${timestamp_to}`,

  // CAMERA ASSIST API
  updateCameraAssist: id => `/cameras/${id}/update/camera-assist`,
  getCameraAssist: id => `/cameras/${id}/get/camera-assist`,

  // PLANE CALIBRATION
  updatePlaneCalibration: id => `/cameras/${id}/update/plane-calibration`,

  // CAMERAS
  getCameraList: search => `/cameras/list?search=${search}`,
  getCameraById: id => `/cameras/${id}`,
  getCamerasByLocation: id => `/locations/${id}/cameras`,
  getCamerasListByLocation: (location_id, camera_view) =>
    `/locations/${location_id}/cameras/list?camera_view=${camera_view}`,
  getCameraLiveImage: id => `/cameras/${id}/live-image`,
  getCameraFootfallImage: id => `/cameras/${id}/footfall-image`,
  createCamera: () => '/cameras/create',
  updateCamera: id => `/cameras/${id}/update`,
  deleteCamera: id => `/cameras/${id}/delete`,
  getCameraTemplateImage: id => `/cameras/${id}/template-image`,
  bulkCameraInsert: () => `/cameras/bulk-camera-insert`,
  bulkUpdateCredentials: () => `cameras/bulk-update-cameras-credentials`,
  getCameraRTSPStatus: id => `/cameras/${id}/rtsp-status`,
  // REFERENCE IMAGE
  getLatestReferenceImage: camera_id =>
    `/reference_image/${camera_id}/get_latest_version`,
  getReferenceImage: id => `/reference_image/${id}/get`,
  uploadReferenceImage: id => `/reference_image/${id}/upload`,
  deleteReferenceImage: id => `/reference_image/${id}/delete`,
  savePlaneCalibration: id => `/reference_image/${id}/save_plane`,
  getPlaneLatestCalibration: camera_id =>
    `/reference_image/${camera_id}/get_latest_plane`,
  getPlaneCalibration: id => `/reference_image/${id}/get_plane`,
  getReferenceImageVersions: camera_id =>
    `/reference_image/${camera_id}/get_versions`,

  // SERVER
  getServerList: search => `/servers/list?search=${search}`,
  getServerById: id => `/servers/${id}`,
  createServer: () => '/servers/create',
  updateServer: () => '/servers/update',
  deleteServer: id => `/servers/${id}/delete`,
  prebootstrapServer: id => `/servers/${id}/prebootstrap`,

  //LOCATION STATUS (Older: LOCATION HEALTH)
  getStatusDataByLocation: id => `/locations/${id}/status`,
  getHealthDataByCamera: id => `/cameras/${id}/health`,
  getAdvancedHealthCheck: id => `/cameras/${id}/advanced-health-check`,
  getBmcStatus: id => `/locations/${id}/bmc-server`,
  saveLocationStatusFlow: id => `/locations/${id}/save_status_flow`,
  getLocationStatusFlow: id => `/locations/${id}/get_status_flow`,
  getLocationEnvironments: id =>
    `/locations/${id}/get_status_flow_environments`,
  getLocationRuntimeStatus: id => `/locations/${id}/get_runtime_status`,
  getLocationPipelineStatus: id => `/locations/${id}/get_pipeline_status`,

  getAllInstallerRole: () => `/locations/get_all_installer_role`,

  // camera validation
  getValidationsByLocation: id => `/validation/location/${id}`,
  requestCameraValidation: id => `/validation/request?camera_id=${id}`,
  sendValidationSupportEmail: cameras =>
    `validation/support-request?cameras=${cameras}`,
  cameraValidated: id => `validation/verified?camera_id=${id}`,
  getCameraValidationComments: id => `validation/comments?camera_id=${id}`,
  addNewComment: id => `/validation/adjustment-required?camera_id=${id}`,
  rollbackValidationForCamera: id => `/validation/camera/${id}/rollback`,

  //VIEW MANAGEMENT:
  getCameraPolygons: (category, cameraId) =>
    `/cameras/polygons/${category}/${cameraId}`,
  updateCameraPolygons: (category, cameraId) =>
    `/cameras/polygons/save/${category}/${cameraId}`,
  getProjectionPolygons: (category, cameraId) =>
    `/cameras/polygons/${category}/${cameraId}`,
  updateProjectionPolygons: (category, cameraId) =>
    `/cameras/polygons/save/${category}/${cameraId}`,

  //ROLES
  getRoles: search => `/roles/list?search=${search}`,
  getRoleDetails: id => `/roles/${id}`,
  createRole: () => '/roles/create',
  updateRole: id => `/roles/${id}/update`,
  getPagesActions: () => '/roles/pages-actions/list',
  findTemplate: () => '/templates/find',

  //TEMPLATE MANAGEMENT
  getTemplates: (id, limit, offset, camera_view, by) =>
    `/${by}/${id}/templates?limit=${limit}&offset=${offset}&camera_view=${camera_view}`,
  getTemplateDetails: templateId => `/templates/${templateId}`,
  deleteTemplate: id => `/templates/${id}/delete`,
  createTemplate: () => '/templates/create',
  createTemplateFork: () => '/forks/create',
  updateTemplate: templateId => `/templates/${templateId}/update`,
  updateTemplateFork: forkId => `/forks/${forkId}/update`,
  getPolygonsByTemplate: id => `/cameras/polygons/camera_objectives/${id}`,
  getCompanyTemplatesBy: (by, id) => `${by}/${id}/all-templates`,
  getTemplateImage: templateId => `templates/${templateId}/image`,
  getTemplateThumbnail: templateId => `templates/${templateId}/thumbnail`,

  // INSTALLATION SUPPORT
  updateInstallationSupport: id => `/installation_support/${id}/update`,
  deleteInstallationSupport: id => `/installation_support/${id}/delete`,

  // STORE LAYOUT
  uploadInstallationPicture: id =>
    `/store_layout/${id}/upload_installation_pictures`,
  getInstallationPictures: (id, level) =>
    `/store_layout/${id}/${level}/get_installation_pictures`,

  //USER WRITE LOGS
  getLogs: (search, startDate, endDate) =>
    `/activities/list?search=${search}&start_date=${startDate}&end_date=${endDate}`,

  //PLANS
  getPlans: () => `/plans/list`,

  //FLOORPLANS
  getEditorFloorplans: id => `/locations/${id}/get_floorplans_for_editor`,
  getFloorplans: id => `/locations/${id}/get_floorplans`,
  uploadFloorplan: id => `/locations/${id}/upload_floorplan`,
  derivateFloorplan: id => `/locations/${id}/derivate_floorplan`,
  updateFloorplan: id => `/floorplan/${id}/update_floorplan`,
  deleteFloorplan: id => `/floorplan/${id}/delete_floorplan`,

  //CE_DEMO
  getDemoCameras: () => 'locations/cameras', // post
  getDemoSearchCameras: () => 'locations/cameras/search', // get
  getLocationsDemo: () => 'locations/',
  getLocationCamerasDemo: () => 'locations/cameras',
  getCategoriesDemo: () => 'events/categories',
  getEventsList: () => 'events',
  addEvent: () => 'events/add',

  getEventsDemo: () => 'events/categories/types',
  getFootagesFilteredDemo: () => 'footages/filter',

  searchFootages: ({ question = '' }) => `vms/footages/search?q=${question}`,
  getAllFootagesDemo: () => 'footages',
  searchFootagesDemo: () => 'footages/search',
  getMyFootagesDemo: () => 'footages/bycreator',
  searchMyFootagesDemo: () => 'footages/bycreator/search',
  getFavoriteFootagesDemo: () => 'footages/favorites',
  searchFavoriteFootagesDemo: () => 'footages/favorites/search',
  deleteFootageDemo: () => 'footages/delete',
};
