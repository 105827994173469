import React from 'react';
import PropTypes from 'prop-types';
import { CheckboxContainer, CheckboxInput } from './Checkbox.styles';
import { FlexContainer } from '../../flexContainer';
import * as icons from '../../icons';

const Checkbox = ({
  size,
  onChange,
  icon,
  id,
  name,
  label,
  checked,
  labelFirst,
  disabled,
  style,
}) => {
  const Icon = icons[icon];
  return (
    <FlexContainer id='pnktlftdom' style={style} gap='10px' align='center'>
      {label && labelFirst && <label htmlFor={id || name}>{label}</label>}
      <CheckboxContainer
        id='hmiwhhpwdq'
        checked={checked}
        disabled={disabled}
        size={size}
      >
        <CheckboxInput
          checked={checked}
          id={id}
          name={name}
          onChange={e => onChange(e)}
          disabled={disabled}
        />
        {checked && (
          <Icon
            id='wfscpkiygx'
            size='extraTiny'
            color={disabled ? 'mediumGrey' : 'white'}
          />
        )}
      </CheckboxContainer>
      {label && !labelFirst && <label htmlFor={id || name}>{label}</label>}
    </FlexContainer>
  );
};

export default Checkbox;

Checkbox.propTypes = {
  size: PropTypes.oneOf(['small, normal']),
  id: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  name: PropTypes.string.isRequired,
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
  icon: PropTypes.string,
  disabled: PropTypes.bool,
  checked: PropTypes.bool.isRequired,
  labelFirst: PropTypes.bool,
  style: PropTypes.shape({ [PropTypes.string]: PropTypes.string }),
};

Checkbox.defaultProps = {
  id: undefined,
  size: 'small',
  label: undefined,
  icon: 'CheckIcon',
  disabled: false,
  labelFirst: false,
  style: undefined,
};
