import styled from 'styled-components';
import SettingsIcon from '@mui/icons-material/Settings';
import TextField from '@mui/material/TextField';

export const Dropdown = styled.div`
  position: absolute;
  display: inline-block;
  left: ${props => props.maxY};
  top: 25px;
  z-index: 2000;
`;

export const DropButton = styled(SettingsIcon)`
  transition: all 1s;
  ${Dropdown}:hover & {
    fill: #d3d3d3;
  }
`;

export const DropdownContent = styled.div`
  position: absolute;
  opacity: 0;
  visibility: hidden;
  right: 1px;
  background-color: #f1f1f1;
  min-width: 180px;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  z-index: 1;
  transition: visibility 0.2s, opacity 0.2s linear;
  ${Dropdown}:hover & {
    visibility: visible;
    opacity: 1;
  }
`;

export const Item = styled.span`
  color: black;
  padding: 12px 16px;
  transition: 0.3s;
  text-decoration: none;
  display: block;
  font-size: 12px;
  font-family: 'Montserrat', sans-serif;
  &:hover {
    background-color: #ddd;
  }
`;

export const PointContextMenu = styled.div`
  z-index: 100;
  width: 150px;
  height: 100px;
  border-radius: 5px;
  background-color: #f1f1f1;
  transition: visibility 0.1s, opacity 0.1s linear;
  visibility: ${props =>
    props.isContextMenuOpen && props.contextMenu.isClickedOnPoint
      ? 'visible'
      : 'hidden'};
  opacity: ${props =>
    props.isContextMenuOpen && props.contextMenu.isClickedOnPoint ? '1' : '0'};
  position: absolute;
  left: ${props => props.contextMenu.x}px;
  top: ${props => props.contextMenu.y}px;
`;

export const PolygonContextMenu = styled.div`
  z-index: 100;
  width: 245px;
  height: 140px;
  border-radius: 5px;
  background-color: #f1f1f1;
  transition: visibility 0.1s, opacity 0.1s linear;
  visibility: ${props =>
    props.isContextMenuOpen && !props.contextMenu.isClickedOnPoint
      ? 'visible'
      : 'hidden'};
  opacity: ${props =>
    props.isContextMenuOpen && !props.contextMenu.isClickedOnPoint ? '1' : '0'};
  position: absolute;
  left: ${props => props.contextMenu.x}px;
  top: ${props => props.contextMenu.y}px;
`;

export const ContextItem = styled.span`
  font-family: 'Montserrat', sans-serif;
  font-size: 14px;
  cursor: pointer;
  transition: all 0.2s ease-in-out;
  padding-left: 10px;
  &:hover {
    color: #5a5a5a;
  }
`;
export const ContextPolygon = styled.span`
  font-family: 'Montserrat', sans-serif;
  font-size: 16px;
  font-weight: bold;
`;

export const ContextProperty = styled.span`
  font-family: 'Montserrat', sans-serif;
  font-size: 12px;
  font-weight: bold;
  padding-left: 10px;
`;

export const ContextTextField = styled(TextField)`
  width: 100%;
  padding-bottom: 20px;
  font-family: Montserrat;
`;
