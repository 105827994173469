import { routeService } from '../../routes/routesList';
import { COLORS } from '../colors';

const { list } = routeService;

export const SIDEBAR_PAGES = [
  {
    ...list.locations_and_cameras,
    subPages: [list.locations_and_groups, list.cameras, list.servers],
  },
  {
    ...list.user_management,
    subPages: [list.users_and_roles],
  },
  list.user_write_log,
  list.version_control,
  {
    ...list.clear_edge,
    subPages: [
      list.live_records,
      list.footages,
      list.events,
      list.generative_ai,
    ],
  },
  {
    ...list.vms_settings,
    subPages: [
      list.setbcameras,
      list.setbstorage,
      list.setbevents,
      list.setbusersettings,
    ],
  },
  {
    ...list.template_management,
    subPages: [list.templates, list.camera_objectives, list.floorplan_editor],
  },
  {
    ...list.overlay_tools,
    subPages: [
      list.camera_view_assist_overlay_tools,
      list.plane_calibration,
      list.lens_rectification,
      list.plane_and_normal,
      list.detection_polygon,
      list.aoi_selection,
      list.floor_projection,
      /* Commented until we use this page */
      // list.object_markers,
    ],
  },
  {
    ...list.installation_management,
    subPages: [
      list.camera_view_assist,
      list.installation_support,
      list.camera_validations,
      list.server_provisioning,
    ],
  },
  {
    ...list.help,
    subPages: [list.info_center],
  },
];

export const ICON_PROPS = {
  color: 'darkGrey',
  hoverColor: COLORS.MainBlue,
  size: 'extraSmall',
};
