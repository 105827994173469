import React from 'react';
import { FlexContainer } from '../flexContainer';
import { notificationPresetsMap } from './constants';
import { BodyTextStroke, Heading6 } from '../fonts';
import { useAppContext } from '../../AppContext';
import { Button } from '../buttons';
import { FlexItem } from '../flexItem';

const Message = ({ title, message, preset }) => {
  const { setActiveNotify } = useAppContext();
  const { icon, color, size } = notificationPresetsMap[preset];
  const formattedMsg =
    typeof message === 'object' ? 'Something went wrong' : message;
  const Icon = icon;
  return (
    <FlexContainer id='hslwpivqaa' gap='16px'>
      <FlexItem id='xxiiykxzwh' alignSelf='flex-start'>
        <Icon id='ujrzammygo' size={size} color={color} />
      </FlexItem>
      <FlexContainer
        id='xqbgoanpsf'
        direction='column'
        align='flex-start'
        gap='5px'
      >
        <Heading6 id='czdbvszlue'>
          <strong>{title}</strong>
        </Heading6>
        <BodyTextStroke id='jzzcqalrfi'>{formattedMsg}</BodyTextStroke>
        <Button
          id='okztvykqdt'
          variant='link'
          onClick={() => setActiveNotify(null)}
        >
          Okay
        </Button>
      </FlexContainer>
    </FlexContainer>
  );
};

export default Message;
