export const PAGES_NAMES = {
  admin: 'admin',
  home: 'home',
  locations_and_cameras: 'locations_and_cameras',
  locations_and_groups: 'locations_and_groups',
  cameras: 'cameras',
  servers: 'servers',
  user_management: 'user_management',
  users_and_roles: 'users_and_roles',
  roles: 'roles',
  users: 'users',
  add_new_role: 'add_new_role',
  update_role: 'update_role',
  user_write_log: 'user_write_log',
  template_management: 'template_management',
  templates: 'templates',
  camera_objectives: 'camera_objectives',
  floorplan_editor: 'floorplan_editor',
  overlay_tools: 'overlay_tools',
  installation_management: 'installation_management',
  camera_view_assist: 'camera_view_assist',
  camera_validations: 'camera_validations',
  lens_rectification: 'lens_rectification',
  plane_and_normal: 'plane_and_normal',
  detection_polygon: 'detection_polygon',
  aoi_selection: 'aoi_selection',
  floor_projection: 'floor_projection',
  object_markers: 'object_markers',
  location_status: 'location_status',
  version_control: 'version_control',
  help: 'help',
  info_center: 'info_center',
  plane_calibration: 'plane_calibration',
  installation_support: 'installation_support',
  server_provisioning: 'server_provisioning',
  retail_app_access: 'retail_app_access',
  clear_edge: 'clear_edge',
  live_records: 'live_records',
  vms_settings: 'vms_settings',
  setbevents: 'setbevents',
  setbcameras: 'setbcameras',
  setbstorage: 'setbstorage',
  //setbusersettings: 'setbusersettings',
  footages: 'footages',
  events: 'events',
  generative_ai: 'generative_ai',
};

export const PAGES_ORDERS = {
  [PAGES_NAMES.locations_and_groups]: 1,
  [PAGES_NAMES.cameras]: 2,
  [PAGES_NAMES.servers]: 3,
  [PAGES_NAMES.users_and_roles]: 4,
  [PAGES_NAMES.user_write_log]: 6,
  [PAGES_NAMES.template_management]: 7,
  [PAGES_NAMES.templates]: 7,
  [PAGES_NAMES.camera_objectives]: 8,
  [PAGES_NAMES.camera_view_assist]: 9,
  [PAGES_NAMES.lens_rectification]: 10,
  [PAGES_NAMES.plane_and_normal]: 11,
  [PAGES_NAMES.detection_polygon]: 12,
  [PAGES_NAMES.aoi_selection]: 13,
  [PAGES_NAMES.object_markers]: 14,
  [PAGES_NAMES.location_status]: 15,
  [PAGES_NAMES.help]: 16,
  [PAGES_NAMES.info_center]: 17,
  [PAGES_NAMES.camera_validations]: 18,
  [PAGES_NAMES.plane_calibration]: 19,
  [PAGES_NAMES.floor_projection]: 20,
  [PAGES_NAMES.server_provisioning]: 21,
  [PAGES_NAMES.retail_app_access]: 22,
  [PAGES_NAMES.live_records]: 24,
  [PAGES_NAMES.footages]: 25,
  [PAGES_NAMES.vms_settings]: 26,
  [PAGES_NAMES.events]: 27,
  [PAGES_NAMES.setbstorage]: 28,
  [PAGES_NAMES.setbevents]: 29,
  //[PAGES_NAMES.setbusersettings]: 30,
  [PAGES_NAMES.setbcameras]: 31,
  [PAGES_NAMES.generative_ai]: 32,
};

export const ROLE_ACTIONS = {
  read: 'read',
  create: 'create',
  update: 'update',
  delete: 'delete',
};

export const ROLE_PERMISSIONS = {
  read: {
    name: 'View',
    actions: [ROLE_ACTIONS.read],
  },
  readWrite: {
    name: 'Edit',
    actions: [
      ROLE_ACTIONS.read,
      ROLE_ACTIONS.create,
      ROLE_ACTIONS.update,
      ROLE_ACTIONS.delete,
    ],
  },
};
