import React from 'react';
// eslint-disable-next-line import/no-cycle
import { Button, Dialog, Heading2, Heading6 } from '../index';

const ReloadModal = ({ onClose, onContinue, isOpen }) => {
  const renderHeader = () => (
    <Heading2 id='osumxafhdk'>You have unsaved changes</Heading2>
  );

  const renderBody = () => {
    return <Heading6 id='kequrrfvia'>Are you sure you want to quit?</Heading6>;
  };

  const renderFooter = () => (
    <>
      <Button
        id='gsjiscsswy'
        data-testid='reload-cancel'
        variant='secondary'
        size='large'
        onClick={onClose}
      >
        No
      </Button>
      <Button
        id='ijuyctxqlp'
        variant='primary'
        size='large'
        colorType='normal'
        onClick={onContinue}
      >
        Yes
      </Button>
    </>
  );

  return (
    <Dialog
      id='mrlthcwgcz'
      zIndex='10000'
      size='small'
      isOpen={isOpen}
      onClose={onClose}
      header={renderHeader()}
      body={renderBody()}
      footer={renderFooter()}
    />
  );
};

export default ReloadModal;
