export function variables() {
  const origin = window && window.location && window.location.origin;

  switch (origin) {
    // Production environment
    case 'https://app.radius.ai':
      return {
        domain: 'radiusaiinc.us.auth0.com',
        client_id: 'c8BqCJpnkC9KeCx4KWEkLHr8BE4ZeNOQ',
        explorer_client_id: 'ryyxmsMz1qdeiVFg8grHIt4QSweJ7aii',
        explorer_secret:
          'oZHXXEO0OgYVZbdecbeMCbAqUgd3owU6afcmKdsC7P380elJVNk8m9SLE8fGdZ2B',
        backend: 'https://app.radius.ai/central/api/v1',
        redirectUri: 'https://app.radius.ai/central',
      };

    // New production environment
    case 'https://central.radius.ai':
      return {
        domain: 'radiusaiinc.us.auth0.com',
        client_id: 'c8BqCJpnkC9KeCx4KWEkLHr8BE4ZeNOQ',
        explorer_client_id: 'ryyxmsMz1qdeiVFg8grHIt4QSweJ7aii',
        explorer_secret:
          'oZHXXEO0OgYVZbdecbeMCbAqUgd3owU6afcmKdsC7P380elJVNk8m9SLE8fGdZ2B',
        backend: 'https://central.radius.ai/central/api/v1',
        redirectUri: 'https://central.radius.ai/central',
      };

    // New Azure production environment
    case 'https://central-app.radius.ai':
      return {
        domain: 'radiusaiinc.us.auth0.com',
        client_id: '6VjHUILPXj8fys4nt5ZBai6MgpDvYMBy',
        explorer_client_id: '6VjHUILPXj8fys4nt5ZBai6MgpDvYMBy',
        explorer_secret:
          'Mb4vB6r_rc2wnmU1RiEkadue0kNb28L_dlB__GiG3E1hy8FY8ieBU47lX_pdqzSC',
        backend: 'https://central-app.radius.ai/central/api/v1',
        redirectUri: 'https://central-app.radius.ai/central',
      };

    // Staging environment
    case 'https://staging.radius.ai':
      return {
        domain: 'radiusaiinc.us.auth0.com',
        client_id: 'c8BqCJpnkC9KeCx4KWEkLHr8BE4ZeNOQ',
        explorer_client_id: 'ryyxmsMz1qdeiVFg8grHIt4QSweJ7aii',
        explorer_secret:
          'oZHXXEO0OgYVZbdecbeMCbAqUgd3owU6afcmKdsC7P380elJVNk8m9SLE8fGdZ2B',
        backend: 'https://staging.radius.ai/central/api/v1',
        redirectUri: 'https://staging.radius.ai/central',
      };

    // New Staging environment
    case 'https://central.stage.radius.ai':
      return {
        domain: 'radiusaiinc.us.auth0.com',
        client_id: 'c8BqCJpnkC9KeCx4KWEkLHr8BE4ZeNOQ',
        explorer_client_id: 'ryyxmsMz1qdeiVFg8grHIt4QSweJ7aii',
        explorer_secret:
          'oZHXXEO0OgYVZbdecbeMCbAqUgd3owU6afcmKdsC7P380elJVNk8m9SLE8fGdZ2B',
        backend: 'https://central.stage.radius.ai/central/api/v1',
        redirectUri: 'https://central.stage.radius.ai/central',
      };

    // New Azure Staging environment
    case 'https://central-app.stage.radius.ai':
      return {
        domain: 'radiusaiinc.us.auth0.com',
        client_id: '6VjHUILPXj8fys4nt5ZBai6MgpDvYMBy',
        explorer_client_id: '6VjHUILPXj8fys4nt5ZBai6MgpDvYMBy',
        explorer_secret:
          'Mb4vB6r_rc2wnmU1RiEkadue0kNb28L_dlB__GiG3E1hy8FY8ieBU47lX_pdqzSC',
        backend: 'https://central-app.stage.radius.ai/central/api/v1',
        redirectUri: 'https://central-app.stage.radius.ai/central',
      };

    // dev testing environment
    case 'https://central.dev.radius.ai':
      return {
        domain: 'radiusaiinc.us.auth0.com',
        client_id: 'c8BqCJpnkC9KeCx4KWEkLHr8BE4ZeNOQ',
        explorer_client_id: 'ryyxmsMz1qdeiVFg8grHIt4QSweJ7aii',
        explorer_secret:
          'oZHXXEO0OgYVZbdecbeMCbAqUgd3owU6afcmKdsC7P380elJVNk8m9SLE8fGdZ2B',
        backend: 'https://central.dev.radius.ai/central/api/v1',
        redirectUri: 'https://central.dev.radius.ai/central',
      };

    // ClearEdge dev testing environment
    case 'https://central-ce.dev.radius.ai':
      return {
        domain: 'radiusaiinc.us.auth0.com',
        client_id: '6VjHUILPXj8fys4nt5ZBai6MgpDvYMBy',
        explorer_client_id: '6VjHUILPXj8fys4nt5ZBai6MgpDvYMBy',
        explorer_secret:
            'Mb4vB6r_rc2wnmU1RiEkadue0kNb28L_dlB__GiG3E1hy8FY8ieBU47lX_pdqzSC',
        backend: 'https://central-ce.dev.radius.ai/central/api/v1',
        redirectUri: 'https://central-ce.dev.radius.ai/central',
      };

    // New Azure dev testing environment
    case 'https://central-app.dev.radius.ai':
      return {
        domain: 'radiusaiinc.us.auth0.com',
        client_id: '6VjHUILPXj8fys4nt5ZBai6MgpDvYMBy',
        explorer_client_id: '6VjHUILPXj8fys4nt5ZBai6MgpDvYMBy',
        explorer_secret:
          'Mb4vB6r_rc2wnmU1RiEkadue0kNb28L_dlB__GiG3E1hy8FY8ieBU47lX_pdqzSC',
        backend: 'https://central-app.dev.radius.ai/central/api/v1',
        redirectUri: 'https://central-app.dev.radius.ai/central',
      };

    // localhost dev environment
    default:
      return {
        domain: 'radiusaiinc.us.auth0.com',
        client_id: 'c8BqCJpnkC9KeCx4KWEkLHr8BE4ZeNOQ',
        explorer_client_id: 'ryyxmsMz1qdeiVFg8grHIt4QSweJ7aii',
        explorer_secret:
          'oZHXXEO0OgYVZbdecbeMCbAqUgd3owU6afcmKdsC7P380elJVNk8m9SLE8fGdZ2B',
        backend: 'http://localhost:8080/central/api/v1',
        redirectUri: 'http://localhost:8080/central',
      };
  }
}
