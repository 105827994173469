import React from 'react';
import { Loader } from '../Loader';
import { FlexContainer } from '../flexContainer';

const RenderHelper = ({
  isLoading,
  isError,
  isData,
  Component,
  ErrorComponent,
}) => {
  if (isLoading)
    return (
      <FlexContainer id='elpfnurlof' style={{ flex: 1 }} fluid>
        <Loader id='tpksdtlgqr' alt='Loading...' />
      </FlexContainer>
    );
  if (isError) return <ErrorComponent id='zdsquhjofm' />;
  if (isData) return <Component id='bsrsxnpths' />;
  return null;
};

export default RenderHelper;
