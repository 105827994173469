// Permissions
import { PAGES_NAMES } from '../permissions';

class Routes {
  list = {
    home: {
      path: 'home',
      name: 'Home',
      icon: null,
      parent: null,
      isRedirected: false,
      pageName: PAGES_NAMES.home,
    },
    admin: {
      path: 'admin',
      name: 'Admin',
      icon: null,
      parent: null,
      isRedirected: false,
    },
    no_access: {
      path: 'no-access',
      name: 'No access',
      icon: null,
      parent: null,
      isRedirected: false,
    },
    no_email_verified: {
      path: 'no-email-verified',
      name: 'Email not verified',
      icon: null,
      parent: null,
      isRedirected: false,
    },
    kiosk_management: {
      path: 'kiosk_management',
      name: 'Kiosk management',
      icon: 'mappin',
      parent: 'admin',
      isRedirected: false,
      pageName: PAGES_NAMES.admin,
    },
    tags: {
      path: 'tags',
      name: 'Tags',
      icon: 'tag',
      parent: 'admin',
      isRedirected: false,
    },
    user_management: {
      path: 'user_management',
      name: 'User Management',
      icon: 'UsersIcon',
      parent: 'admin',
      isRedirected: false,
      pageName: PAGES_NAMES.user_management,
    },
    add_new_role: {
      path: 'role/add',
      name: 'Add New Role',
      icon: null,
      parent: 'users_and_roles',
      isRedirected: false,
      pageName: PAGES_NAMES.add_new_role,
    },
    update_role: {
      path: 'role/:id/edit',
      name: 'Edit Role',
      icon: null,
      parent: 'users_and_roles',
      isRedirected: false,
      pageName: PAGES_NAMES.update_role,
    },
    users_and_roles: {
      path: 'users_and_roles',
      name: 'Users & Roles',
      icon: null,
      parent: 'user_management',
      isRedirected: true,
      pageName: PAGES_NAMES.users_and_roles,
    },
    roles: {
      path: 'roles',
      name: 'Roles',
      icon: null,
      parent: 'users_and_roles',
      isRedirected: false,
      pageName: PAGES_NAMES.roles,
    },
    users: {
      path: 'users',
      name: 'Users',
      icon: null,
      parent: 'users_and_roles',
      isRedirected: false,
      pageName: PAGES_NAMES.users,
    },
    user_write_log: {
      path: 'user_write_log',
      name: 'User Write Log',
      icon: 'UserWriteLogIcon',
      parent: 'admin',
      isRedirected: true,
      pageName: PAGES_NAMES.user_write_log,
    },
    clear_edge: {
      path: 'clear_edge',
      name: 'Clear Edge',
      icon: 'ClearEdgeIcon',
      parent: 'admin',
      isRedirected: false,
      pageName: PAGES_NAMES.clear_edge,
    },
    live_records: {
      path: 'live_records',
      name: 'Live Streams',
      icon: null,
      parent: 'clear_edge',
      isRedirected: true,
      pageName: PAGES_NAMES.live_records,
    },
    footages: {
      path: 'footages',
      name: 'Footages',
      icon: null,
      parent: 'clear_edge',
      isRedirected: true,
      pageName: PAGES_NAMES.footages,
    },
    generative_ai: {
      path: 'generative_ai',
      name: 'GenAI (beta)',
      icon: null,
      parent: 'clear_edge',
      isRedirected: true,
      pageName: PAGES_NAMES.generative_ai,
    },
    vms_settings: {
      path: 'vms_settings',
      name: 'Settings',
      icon: 'SettingsIcon',
      parent: 'admin',
      isRedirected: false,
      pageName: PAGES_NAMES.vms_settings,
    },
    setbcameras: {
      path: 'setbcameras',
      name: 'Cameras',
      icon: null,
      parent: 'vms_settings',
      isRedirected: true,
      pageName: PAGES_NAMES.setbcameras,
    },
    setbstorage: {
      path: 'setbstorage',
      name: 'Storage',
      icon: null,
      parent: 'vms_settings',
      isRedirected: true,
      pageName: PAGES_NAMES.setbstorage,
    },
    setbevents: {
      path: 'setbevents',
      name: 'Event Settings',
      icon: null,
      parent: 'vms_settings',
      isRedirected: true,
      pageName: PAGES_NAMES.setbevents,
    },
    setbusersettings: {
      path: 'setbusersettings',
      name: 'User Settings',
      icon: null,
      parent: 'vms_settings',
      isRedirected: true,
      pageName: PAGES_NAMES.setbusersettings,
    },
    set01: {
      path: 'set01',
      name: 'Settings_1',
      icon: null,
      parent: 'setbcameras',
      isRedirected: true,
      pageName: PAGES_NAMES.set1,
    },
    set02: {
      path: 'set02',
      name: 'Settings_2',
      icon: null,
      parent: 'setbcameras',
      isRedirected: true,
      pageName: PAGES_NAMES.set2,
    },
    set03: {
      path: 'set03',
      name: 'Settings_3',
      icon: null,
      parent: 'setbcameras',
      isRedirected: true,
      pageName: PAGES_NAMES.set03,
    },
    set04: {
      path: 'set04',
      name: 'Settings_4',
      icon: null,
      parent: 'setbcameras',
      isRedirected: true,
      pageName: PAGES_NAMES.set04,
    },
    set011: {
      path: 'set011',
      name: 'Settings_3',
      icon: null,
      parent: 'setbevents',
      isRedirected: true,
      pageName: PAGES_NAMES.set011,
    },
    set012: {
      path: 'set012',
      name: 'Settings_4',
      icon: null,
      parent: 'setbevents',
      isRedirected: true,
      pageName: PAGES_NAMES.set012,
    },
    set021: {
      path: 'set021',
      name: 'Settings_5',
      icon: null,
      parent: 'setbstorage',
      isRedirected: true,
      pageName: PAGES_NAMES.set021,
    },
    set022: {
      path: 'set022',
      name: 'Settings_6',
      icon: null,
      parent: 'setbstorage',
      isRedirected: true,
      pageName: PAGES_NAMES.set022,
    },
    schedule: {
      path: 'schedule',
      name: 'Schedule',
      icon: null,
      parent: 'setbstorage',
      isRedirected: true,
      pageName: PAGES_NAMES.schedule,
    },
    set031: {
      path: 'set031',
      name: 'Settings_031',
      icon: null,
      parent: 'setbusersettings',
      isRedirected: true,
      pageName: PAGES_NAMES.set031,
    },
    set032: {
      path: 'set032',
      name: 'Settings_032',
      icon: null,
      parent: 'setbusersettings',
      isRedirected: true,
      pageName: PAGES_NAMES.set032,
    },
    events: {
      path: 'events',
      name: 'Events',
      icon: null,
      parent: 'clear_edge',
      isRedirected: true,
      pageName: PAGES_NAMES.events,
    },
    template_management: {
      path: 'template_management',
      name: 'Template Management',
      icon: 'TemplateSimpleIcon',
      parent: 'admin',
      isRedirected: false,
      pageName: PAGES_NAMES.template_management,
    },
    templates: {
      path: 'templates',
      name: 'Templates',
      parent: 'template_management',
      isRedirected: true,
      pageName: PAGES_NAMES.templates,
    },
    camera_objectives: {
      path: 'camera_objectives',
      name: 'Camera Objectives',
      icon: null,
      parent: 'template_management',
      isRedirected: true,
      pageName: PAGES_NAMES.camera_objectives,
    },
    floorplan_editor: {
      path: 'floorplan_editor',
      name: 'Floor Plan Editor',
      icon: null,
      parent: 'template_management',
      isRedirected: true,
      pageName: PAGES_NAMES.floorplan_editor,
    },
    locations_and_cameras: {
      path: 'locations_and_cameras',
      name: 'Setup', // Locations & Cameras
      icon: 'ListIcon',
      parent: 'admin',
      isRedirected: false,
      pageName: PAGES_NAMES.locations_and_cameras,
    },
    locations_and_groups: {
      path: 'locations_and_groups',
      name: 'Locations & Groups',
      icon: null,
      parent: 'locations_and_cameras',
      isRedirected: true,
      pageName: PAGES_NAMES.locations_and_groups,
    },
    cameras: {
      path: 'cameras',
      name: 'Cameras',
      icon: null,
      parent: 'locations_and_cameras',
      isRedirected: true,
      pageName: PAGES_NAMES.cameras,
    },
    servers: {
      path: 'servers',
      name: 'Servers',
      icon: null,
      parent: 'locations_and_cameras',
      isRedirected: true,
      pageName: PAGES_NAMES.servers,
    },
    overlay_tools: {
      path: 'overlay_tools',
      name: 'Overlay Tools',
      icon: 'CameraIcon',
      parent: 'admin',
      isRedirected: false,
      pageName: PAGES_NAMES.overlay_tools,
    },
    camera_view_assist_overlay_tools: {
      // Cannot Duplicate, added underscore see related task -> CC-384
      path: 'camera_view_assist',
      name: 'Camera View Assist',
      parent: 'overlay_tools',
      isRedirected: true,
      pageName: PAGES_NAMES.camera_view_assist,
    },
    plane_calibration: {
      path: 'plane_calibration',
      name: 'Plane Calibration',
      icon: null,
      parent: 'overlay_tools',
      isRedirected: true,
      pageName: PAGES_NAMES.plane_calibration,
    },
    lens_rectification: {
      path: 'lens_rectification',
      name: 'Lens Rectification',
      icon: null,
      parent: 'overlay_tools',
      isRedirected: true,
      pageName: PAGES_NAMES.lens_rectification,
    },
    plane_and_normal: {
      path: 'plane_and_normal',
      name: 'Plane and Normal',
      icon: null,
      parent: 'overlay_tools',
      isRedirected: true,
      pageName: PAGES_NAMES.plane_and_normal,
    },
    detection_polygon: {
      path: 'detection_polygon',
      name: 'Detection Polygon',
      icon: null,
      parent: 'overlay_tools',
      isRedirected: true,
      pageName: PAGES_NAMES.detection_polygon,
    },
    aoi_selection: {
      path: 'aoi_selection',
      name: 'AOI Selection',
      icon: null,
      parent: 'overlay_tools',
      isRedirected: true,
      pageName: PAGES_NAMES.aoi_selection,
    },
    floor_projection: {
      path: 'floor_projection',
      name: 'Floor Projection Zone',
      icon: null,
      parent: 'overlay_tools',
      isRedirected: true,
      pageName: PAGES_NAMES.floor_projection,
    },
    // Commented until we use this page
    // object_markers: {
    //   path: 'object_markers',
    //   name: 'Object Markers',
    //   icon: null,
    //   parent: 'overlay_tools',
    //   isRedirected: true,
    //   pageName: PAGES_NAMES.object_markers,
    // },
    installation_management: {
      path: 'installation_management',
      name: 'Installation Management',
      icon: 'DataIcon',
      parent: 'admin',
      isRedirected: false,
      pageName: PAGES_NAMES.installation_management,
    },
    camera_view_assist: {
      path: 'camera_view_assist',
      name: 'Camera View Assist',
      parent: 'installation_management',
      isRedirected: true,
      pageName: PAGES_NAMES.camera_view_assist,
    },
    installation_support: {
      path: 'installation_support',
      name: 'Installation Support',
      icon: null,
      parent: 'installation_management',
      isRedirected: true,
      pageName: PAGES_NAMES.installation_support,
    },
    camera_validations: {
      path: 'camera_validations',
      name: 'Camera Validations',
      parent: 'installation_management',
      isRedirected: true,
      pageName: PAGES_NAMES.camera_validations,
    },
    server_provisioning: {
      path: 'server_provisioning',
      name: 'Server Provisioning',
      parent: 'installation_management',
      isRedirected: true,
      pageName: PAGES_NAMES.server_provisioning,
    },
    version_control: {
      path: 'version_control',
      name: 'Version Control',
      icon: 'VersionPageIcon',
      parent: 'admin',
      isRedirected: true,
      pageName: PAGES_NAMES.version_control,
    },
    location_status: {
      path: 'location_status',
      name: 'Location Status',
      icon: 'LocationIcon',
      parent: 'admin',
      isRedirected: true,
      pageName: PAGES_NAMES.location_status,
    },
    help: {
      path: 'help',
      name: 'Help',
      icon: 'NoticeIcon',
      parent: 'admin',
      isRedirected: false,
      pageName: PAGES_NAMES.help,
    },
    info_center: {
      path: 'info_center',
      name: 'Info Center',
      icon: null,
      parent: 'help',
      isRedirected: true,
      pageName: PAGES_NAMES.info_center,
    },
    terms: {
      path: 'terms',
      name: 'Terms and Conditions',
      icon: null,
      parent: null,
      isRedirected: false,
    },
  };

  getRelativePath = ({ path, item }) =>
    path === '/' ? `${path}${item.path}` : `${path}/${item.path}`;

  getPath = ({ item, stop, forwardSlash = true }) => {
    let parent = this.list[item.parent];
    let { path } = item;

    while (parent || parent !== stop) {
      path = `${parent.path}/${path}`;

      parent = this.list[parent.parent];
    }

    return forwardSlash ? `/${path}` : path;
  };

  getCategoryPath = ({ item, forwardSlash = true, star = true }) => {
    return forwardSlash
      ? `/${item.path}${star ? '/*' : ''}`
      : `${item.path}${star ? '/*' : ''}`;
  };
}

const routeService = new Routes();

export { routeService };
