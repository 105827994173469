import isEmpty from 'lodash.isempty';

export const getCorrectPositionValue = (options = [], state) => {
  // in case if select is multi select
  if (Array.isArray(state)) return state;
  const matchedOption = options.filter(option => {
    if (option.value?.id) {
      return option.value?.id === state;
    }
    if (state?.value) {
      return option.value === state.value;
    }
    return option.value === state;
  });

  if (isEmpty(matchedOption)) {
    return null;
  }
  return matchedOption[0];
};
