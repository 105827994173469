/* eslint-disable object-shorthand */
import React, { Fragment } from 'react';
import { Line } from 'react-konva';
import { hexToRgb } from '../utils';
import Transform from './Transform';

const Lines = ({
  isMagneticModeOn,
  setGuidingLines,
  polygons,
  isNearPoint,
  onDragMove,
  isPolygonDrawing,
  setPolygons,
  transformationPoints,
  setIsPolygonDrawing,
  setAreActionsDisabled,
  transform,
  setWasPointMoved,
  isUserActionPermitted,
  isStyledForZone = true,
  stageWidth,
  stageHeight,
  isAnythingMoving,
  setIsAnythingMoving,
  setIsDisableLines,
  isDisableLines,
  setContextMenu,
  setIsContextMenuOpen,
  imgRealWidth,
  imgRealHeigth,
  showTransformPoints,
  setCursorCoordinates,
  transformationPointRadius,
  isRestrictModeOn,
  cursorCoordinates,
  guidingLines,
  onDragEnd,
}) => {
  const getLineActions = id => {
    if (isUserActionPermitted) {
      return {
        onDragMove: e => onDragMove(id, e),
        onDragEnd: e => onDragEnd(id, e),
        onMouseEnter: handleMouseEnter,
        onMouseLeave: handleMouseLeave,
        onContextMenu: e => handleRightClick(id, e),
      };
    }
  };

  const handleRightClick = (id, e) => {
    const clickedPolygon = polygons.find(polygon => polygon.id === id);
    const clickedPolygonName = clickedPolygon.name;
    if (!isPolygonDrawing) {
      const { x, y } = e.target.getStage().getPointerPosition();
      setContextMenu({
        x: x,
        y: y,
        clickedPolygon: clickedPolygon,
        isClickedOnPoint: false,
        polygonName: clickedPolygonName,
      });
      setIsContextMenuOpen(true);
      e.evt.preventDefault();
    }
  };

  const handleMouseEnter = event => {
    const container = event.target.getStage().container();
    container.style.cursor = 'pointer';
  };

  const handleMouseLeave = event => {
    const container = event.target.getStage().container();
    container.style.cursor = 'crosshair';
  };

  // const renderZoneText = (name, points, offsetX, offsetY) => {
  //   if (isStyledForZone) {
  //     let posX = 0;
  //     let posY = 0;
  //     for (let i = 0; i < points.length; i++) {
  //       if (i % 2 === 0) posX += points[i];
  //       else posY += points[i];
  //     }
  //     return (
  //       <Text
  //         text={name}
  //         fontFamily='Montserrat'
  //         x={posX / (points.length / 2)}
  //         y={posY / (points.length / 2)}
  //         offsetY={-offsetY}
  //         offsetX={-offsetX + 25}
  //         fontStyle='bold'
  //         fontSize='16'
  //       />
  //     );
  //   }
  // };
  // somehow using functions cannot render need to copy paste twice to make inaccesible polygon to be on the top.
  return (
    <>
      {polygons.map(polygon => {
        const { id, x, y, points, isClosed, color, offsetX, offsetY } = polygon;
        const { red, green, blue } = hexToRgb(color);
        const stroke = `rgba(${red},${green},${blue}, 1)`;
        const fill = `rgba(${red},${green},${blue}, 0.4)`;
        if (!polygon.isInaccesable)
          return (
            <Fragment key={id}>
              {/* {renderZoneText(name, points, offsetX, offsetY)} */}
              <Line
                {...getLineActions(id)}
                lineCap='round'
                x={x + offsetX}
                y={y + offsetY}
                points={points}
                closed={isNearPoint && isMagneticModeOn ? 'true' : isClosed}
                draggable={!isPolygonDrawing && isUserActionPermitted}
                fill={polygon.isInaccesable ? 'rgba(83, 83, 83, 0.6)' : fill}
                zIndex={polygon.isInaccesable ? 2 : 1}
                stroke={isPolygonDrawing || isAnythingMoving ? 'black' : ''}
                strokeWidth={1}
              />
              {isClosed && transform && (
                <Transform
                  polygonId={id}
                  transformationPointRadius={transformationPointRadius}
                  polygons={polygons}
                  setPolygons={setPolygons}
                  pointsList={points}
                  offsetY={offsetY}
                  offsetX={offsetX}
                  stroke={stroke}
                  setIsPolygonDrawing={setIsPolygonDrawing}
                  setAreActionsDisabled={setAreActionsDisabled}
                  draggable={isUserActionPermitted}
                  setWasPointMoved={setWasPointMoved}
                  isStyledForZone={isStyledForZone}
                  stageWidth={stageWidth}
                  stageHeight={stageHeight}
                  setIsDisableLines={setIsDisableLines}
                  isDisableLines={isDisableLines}
                  setIsAnythingMoving={setIsAnythingMoving}
                  setContextMenu={setContextMenu}
                  setIsContextMenuOpen={setIsContextMenuOpen}
                  showTransformPoints={showTransformPoints}
                  setCursorCoordinates={setCursorCoordinates}
                  imgRealWidth={imgRealWidth}
                  imgRealHeigth={imgRealHeigth}
                  isRestrictModeOn={isRestrictModeOn}
                  guidingLines={guidingLines}
                  transformationPoints={transformationPoints}
                  cursorCoordinates={cursorCoordinates}
                  setGuidingLines={setGuidingLines}
                  isMagneticModeOn={isMagneticModeOn}
                />
              )}
            </Fragment>
          );
        return <Line key={id} />;
      })}
      {polygons.map(polygon => {
        const { id, x, y, points, isClosed, color, offsetX, offsetY } = polygon;
        const { red, green, blue } = hexToRgb(color);
        const stroke = `rgba(${red},${green},${blue}, 1)`;
        const fill = `rgba(${red},${green},${blue}, 0.4)`;
        if (polygon.isInaccesable)
          return (
            <Fragment key={id}>
              {/* {renderZoneText(id, points, offsetX, offsetY)} */}
              <Line
                {...getLineActions(id)}
                lineCap='round'
                x={x + offsetX}
                y={y + offsetY}
                points={points}
                closed={isClosed}
                draggable={!isPolygonDrawing && isUserActionPermitted}
                fill={polygon.isInaccesable ? 'rgba(83, 83, 83, 0.6)' : fill}
                zIndex={polygon.isInaccesable ? 2 : 1}
                stroke={isPolygonDrawing || isAnythingMoving ? 'black' : ''}
                strokeWidth={1}
              />
              {isClosed && transform && (
                <Transform
                  polygonId={id}
                  transformationPointRadius={transformationPointRadius}
                  polygons={polygons}
                  setPolygons={setPolygons}
                  pointsList={points}
                  offsetY={offsetY}
                  offsetX={offsetX}
                  stroke={stroke}
                  setIsPolygonDrawing={setIsPolygonDrawing}
                  setAreActionsDisabled={setAreActionsDisabled}
                  draggable={isUserActionPermitted}
                  setWasPointMoved={setWasPointMoved}
                  isStyledForZone={isStyledForZone}
                  stageWidth={stageWidth}
                  stageHeight={stageHeight}
                  setIsDisableLines={setIsDisableLines}
                  isDisableLines={isDisableLines}
                  setIsAnythingMoving={setIsAnythingMoving}
                  setContextMenu={setContextMenu}
                  setIsContextMenuOpen={setIsContextMenuOpen}
                  showTransformPoints={showTransformPoints}
                  setCursorCoordinates={setCursorCoordinates}
                  imgRealWidth={imgRealWidth}
                  imgRealHeigth={imgRealHeigth}
                  isRestrictModeOn={isRestrictModeOn}
                  guidingLines={guidingLines}
                  transformationPoints={transformationPoints}
                  cursorCoordinates={cursorCoordinates}
                  setGuidingLines={setGuidingLines}
                />
              )}
            </Fragment>
          );
        return <Line key={id} />;
      })}
    </>
  );
};

export default Lines;
