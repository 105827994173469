// Libs
import isObject from 'lodash.isobject';
// Permissions
import { ROLE_PERMISSIONS } from '../permissions';

export const formatListData = (data, key, template) => {
  if (data && Array.isArray(data)) {
    return data.map(item => template(item));
  }
  if (data && data[key]) return data[key].map(item => template(item));
  return [];
};

export const logsValuesArray = (data, list) => {
  return data.map(page => {
    if (isObject(page)) {
      //array of objects
      const displayNameKey = Object.keys(list).find(
        pageKey => pageKey === page.name
      );
      const pageName = list[displayNameKey].name;

      const listOfActions = page.actions;
      const isEditPermission = ROLE_PERMISSIONS.readWrite.actions.every(
        action => {
          return listOfActions.includes(action);
        }
      );
      let permissionName;
      if (isEditPermission) {
        permissionName = ROLE_PERMISSIONS.readWrite.name;
      } else {
        permissionName = ROLE_PERMISSIONS.read.name;
      }
      return `${pageName} (${permissionName})`;
    }
    return page;
  });
};
