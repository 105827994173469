// Libs
import styled, { css } from 'styled-components';
// Constants
import { COLORS } from '../colors';

const variant = {
  primary: 'primary',
  secondary: 'secondary',
  filter: 'filter',
  action: 'action',
  link: 'link',
};

const size = {
  large: 'large',
  medium: 'medium',
  small: 'small',
};

const primary = css`
  min-width: 150px;
  padding: 12px 16px;

  font-size: 16px;
  line-height: 24px;

  border: none;
  color: ${COLORS.White};

  background-color: ${props => {
    if (props.disabled) {
      return COLORS.NeutralGray;
    }
    return props.colorType === 'danger' ? COLORS.Red : COLORS.MainBlue;
  }};

  &:hover {
    box-shadow: ${props =>
      props.disabled ? 'none' : '0 4px 6px rgba(18, 104, 251, 0.2)'};
    background-color: ${props => {
      if (props.disabled) {
        return COLORS.NeutralGray;
      }
      return props.colorType === 'danger' ? COLORS.Red : COLORS.SecondaryBlue1;
    }};
  }
`;

const secondary = css`
  width: auto;
  min-width: auto;
  padding: 12px 24px;

  font-size: 16px;
  line-height: 24px;

  border: 1px solid #e5e5e5;
  background-color: ${COLORS.White};
  color: ${COLORS.TextBlack};

  opacity: ${props => (props.disabled ? '45%' : '100%')};

  &:hover {
    box-shadow: 0 0 6px rgba(229, 229, 229, 0.65);
  }
`;

const filter = css`
  width: auto;
  min-width: auto;
  padding: 12px 24px;

  font-size: 16px;
  line-height: 24px;

  border: 1px solid #e5e5e5;
  background-color: ${COLORS.White};
  color: ${COLORS.TextBlack};

  opacity: ${props => (props.disabled ? '45%' : '100%')};

  &:hover {
    color: ${COLORS.White};
    background-color: ${COLORS.SecondaryBlue1};
  }
`;

const link = css`
  width: auto;
  background-color: transparent;
  font-size: 16px;
  line-height: 20px;
  color: ${COLORS.MainBlue};
  border: none;
  opacity: ${props => (props.disabled ? '45%' : '100%')};
  &:hover {
    color: ${COLORS.SecondaryBlue1};
    text-decoration: ${({ disabled }) => (disabled ? 'none' : 'underline')};
  }
`;

const large = css`
  padding: ${props => (props.variant === 'link' ? '0' : '12px 24px')};
  font-size: 16px;
  line-height: 24px;
`;

const small = css`
  width: auto;
  min-width: auto;
  padding: ${props => (props.variant === 'link' ? '0' : '6px 16px')};

  font-size: 14px;
  line-height: 22px;
`;

const medium = css`
  width: auto;
  min-width: auto;
  padding: ${props => (props.variant === 'link' ? '0' : '9px 20px')};

  font-size: 14px;
  line-height: 22px;
`;

// BUTTON
// props:
// variant='primary', 'secondary', 'action', 'link'
// size='large', 'small'
// colorType='normal', 'danger'
// disabled
export const Button = styled.button`
  ${props => {
    switch (props.variant) {
      case variant.primary:
        return primary;
      case variant.secondary:
        return secondary;
      case variant.link:
        return link;
      case variant.filter:
        return filter;
      default:
        return primary;
    }
  }}
  ${props => {
    switch (props.size) {
      case size.small:
        return small;
      case size.medium:
        return medium;
      case size.large:
        return large;
      default:
        return props.variant === variant.link ? small : large;
    }
  }}
  cursor: ${props => (props.disabled ? 'initial' : 'pointer')};
  font-family: 'Montserrat', sans-serif;
  font-style: normal;
  font-weight: 600;
  border-radius: 8px;
`;

export const TermsButton = styled.button`
  all: unset;
  font-family: 'Montserrat', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;

  text-align: center;
  letter-spacing: -0.02em;
  text-decoration-line: underline;

  color: ${COLORS.SecondaryBlue1};

  mix-blend-mode: normal;

  margin-top: 47px;
  variant: link;
`;

export const FetchButton = styled.button`
  all: unset;
  width: 73px;
  height: 20px;

  font-family: 'Montserrat', sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  /* identical to box height, or 143% */
  display: flex;
  align-items: center;
  text-align: center;
  letter-spacing: 0.5px;

  color: ${COLORS.White};

  flex: none;
  order: 0;
  flex-grow: 0;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 6px 16px;

  width: 105px;
  height: 32px;

  background: ${COLORS.MainBlue};
  border-radius: 6px;

  flex: none;
  order: 3;
  flex-grow: 0;
`;
