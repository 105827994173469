import axios from 'axios';
import { variables } from '../utils/variables';

axios.defaults.withCredentials = true;

export const api = axios.create({
  baseURL: variables().backend || 'http://localhost:8080/central/api/v1',
  headers: {
    'Content-Type': 'application/json',
  },
  withCredentials: true,
});

export const CE_DEMO_API = axios.create({
  baseURL: 'https://central.stage.radius.ai/demo/', //{variables().backend ||}https://central.stage.radius.ai/central/demo/

  headers: {
    'Content-Type': 'application/json',
  },
  withCredentials: false,
});

const apiFetch = (url, data, config) => {
  return api.post(url, data, config).then(res => res.data);
};

export const demoApiFetch = (url, data, config) => {
  return CE_DEMO_API.post(url, data, config).then(res => res.data);
};
export const demoApiFetchGet = (url, config) => {
  return CE_DEMO_API.get(url, config).then(res => res.data);
};

export default apiFetch;
