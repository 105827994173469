/* eslint-disable object-shorthand */
export const PIXEL_RADIUS = 10;

export const getRandomColor = () => {
  const letters = '0123456789ABCDEF';
  let color = '#';
  for (let i = 0; i < 6; i++) {
    color += letters[Math.floor(Math.random() * 16)];
  }
  return color;
};

export const hexToRgb = hex => {
  const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
  return result
    ? {
        red: parseInt(result[1], 16),
        green: parseInt(result[2], 16),
        blue: parseInt(result[3], 16),
      }
    : null;
};

export const normalizePoint = (imageWidth, imageHeight, point) => {
  const [x, y] = point;
  const normalizedX = x / imageWidth;
  const normalizedY = y / imageHeight;
  return [normalizedX, normalizedY];
};

export const denormalizeRectSize = (imageWidth, imageHeight, size) => {
  const [width, height] = size;
  const denormalizedWidth = width * imageWidth;
  const denormalizedHeight = height * imageHeight;
  return [denormalizedWidth, denormalizedHeight];
};

export const denormalizePoint = (imageWidth, imageHeight, point) => {
  const [x, y] = point;
  const denormalizedX = x * imageWidth;
  const denormalizedY = y * imageHeight;
  return [denormalizedX, denormalizedY];
};

export const denormalizePoints = (imageWidth, imageHeight, points) => {
  return points?.map(point => denormalizePoint(imageWidth, imageHeight, point));
};

export const convertPolygonsToLinesView = (
  imageWidth,
  imageHeight,
  polygons
) => {
  return polygons.map(polygon => {
    return {
      ...polygon,
      points: denormalizePoints(imageWidth, imageHeight, polygon.points).flat(),
      offsetX: polygon.offsetX * imageWidth,
      offsetY: polygon.offsetY * imageHeight,
    };
  });
};

export const convertPolygonsToRectangleView = (
  imageWidth,
  imageHeight,
  polygons
) => {
  return polygons.map(polygon => {
    const { width, height } = polygon;
    const [x, y] = denormalizePoint(imageWidth, imageHeight, [
      polygon.x,
      polygon.y,
    ]);
    const [denormalizeWidth, denormalizeHeight] = denormalizeRectSize(
      imageWidth,
      imageHeight,
      [width, height]
    );
    return {
      ...polygon,
      x,
      y,
      width: denormalizeWidth,
      height: denormalizeHeight,
    };
  });
};

export const convertPolygonsToCoordinatePatch = (
  imageWidth,
  imageHeight,
  polygons
) => {
  const patchCoordinates = [];

  polygons.map(function denormalizer(polygon) {
    const RES_X = 1920;
    const RES_Y = 1440;
    const SCALE_X = RES_X / imageWidth;
    const SCALE_Y = RES_Y / imageHeight;
    const { width, height } = polygon;
    const [x, y] = denormalizePoint(imageWidth, imageHeight, [
      polygon.x,
      polygon.y,
    ]);
    const [denormalizeWidth, denormalizeHeight] = denormalizeRectSize(
      imageWidth,
      imageHeight,
      [width, height]
    );
    patchCoordinates.push(Math.floor(SCALE_X * x));
    patchCoordinates.push(Math.floor(SCALE_Y * y));
    patchCoordinates.push(Math.floor(SCALE_X * (x + denormalizeWidth)));
    patchCoordinates.push(Math.floor(SCALE_Y * (y + denormalizeHeight)));
    // eslint-disable-next-line no-sequences
    return x, y, denormalizeWidth, denormalizeHeight;
  });

  return patchCoordinates;
};

export const convertPointsToCornerDetection = (
  imageWidth,
  imageHeight,
  points
) => {
  const cornerPoints = [];

  points.map(function denormalizer(point) {
    const RES_X = 1920;
    const RES_Y = 1440;
    const SCALE_X = RES_X / imageWidth;
    const SCALE_Y = RES_Y / imageHeight;
    const [x, y] = denormalizePoint(imageWidth, imageHeight, [
      point[0],
      point[1],
    ]);

    cornerPoints.push(Math.floor(SCALE_X * x));
    cornerPoints.push(Math.floor(SCALE_Y * y));

    // eslint-disable-next-line no-sequences
    return x, y;
  });

  return cornerPoints;
};

export const checkIsPointNearStartPoint = (startPoint, point) => {
  const [x, y] = point;
  const [firstPointX, firstPointY] = startPoint;
  const maxPointX = firstPointX + PIXEL_RADIUS;
  const minPointX = firstPointX - PIXEL_RADIUS;
  const maxPointY = firstPointY + PIXEL_RADIUS;
  const minPointY = firstPointY - PIXEL_RADIUS;

  return x < maxPointX && x > minPointX && y > minPointY && y < maxPointY;
};

export function distanceBetweenPoints(x1, y1, x2, y2) {
  const xDiff = x2 - x1;
  const yDiff = y2 - y1;
  return Math.sqrt(xDiff * xDiff + yDiff * yDiff);
}

export function checkNeigboors(polygons, polygonId, pointIndex) {
  const neigboors = [];
  polygons.map(polygon => {
    return polygon.linkedTo.map((points, index) => {
      return points.map(point => {
        if (point.polygonId === polygonId && point.pointIndex === pointIndex) {
          neigboors.push({ polygonId: polygon.id, pointIndex: index });
        }
        return point;
      });
    });
  });
  return neigboors;
}

export function linkedPoints(polygons, polygonId, pointIndex) {
  const neigboors = [];
  polygons.map(polygon => {
    return polygon.linkedTo.map((points, index) => {
      return points.map(point => {
        if (point.polygonId === polygonId && point.pointIndex === pointIndex) {
          neigboors.push({ polygonId: polygon.id, pointIndex: index });
        }
        return point;
      });
    });
  });
  neigboors.push({ polygonId: polygonId, pointIndex: pointIndex });
  return neigboors;
}

export function getLinkedPolygons(polygons, polygonId, neighboors = []) {
  polygons.map(polygon => {
    if (polygon.id === polygonId) {
      polygon.linkedTo.map(points => {
        if (points.length > 0)
          points.map(point => {
            if (!neighboors.includes(point.polygonId)) {
              neighboors.push(point.polygonId);
              getLinkedPolygons(polygons, point.polygonId, neighboors);
            }
            return point;
          });
        return points;
      });
    }
    return polygon;
  });
  return neighboors.filter(neighboor => neighboor !== polygonId);
}

export function angleBetweenPoints(cx, cy, ex, ey) {
  const dy = ey - cy;
  const dx = ex - cx;
  let theta = Math.atan2(dy, dx);
  theta *= 180 / Math.PI;
  if (theta < 0) theta = 360 + theta;
  if (theta > 180) theta = 360 - theta;

  theta = Math.round(theta);
  // text offset
  const offsetX = dx * 100;
  const offsetY = dy * 100;
  return { angle: theta, offsetX: offsetX, offsetY: offsetY };
}

export function angleBetweenLines(p1, p2, p3, p4) {
  function calcangle(x00, y00, x01, y01, x10, y10, x11, y11) {
    const dx0 = x01 - x00;
    const dy0 = y01 - y00;
    const dx1 = x11 - x10;
    const dy1 = y11 - y10;
    const angle =
      (Math.atan2(dx0 * dy1 - dx1 * dy0, dx0 * dx1 + dy0 * dy1) * 180) /
      Math.PI;
    return Math.round(Math.abs(angle));
  }
  return calcangle(p1.x, p1.y, p2.x, p2.y, p3.x, p3.y, p4.x, p4.y);
}

export function getPointNeighboors(
  polygon,
  point,
  pointIndex,
  maxIndex,
  angles = [],
  returnWithoutCalling = false
) {
  // if last element -> 0
  // if first element -> length of array
  const pointNeighboors = [pointIndex - 1, pointIndex + 1];
  pointNeighboors.map((index, index_) => {
    if (index < 0) pointNeighboors[index_] = maxIndex;
    if (index === maxIndex + 1) pointNeighboors[index_] = 0;
    return index;
  });
  const angle = angleBetweenLines(
    {
      x: polygon.points[pointNeighboors[1]][0],
      y: polygon.points[pointNeighboors[1]][1],
    },
    { x: point[0], y: point[1] },
    {
      x: polygon.points[pointNeighboors[0]][0],
      y: polygon.points[pointNeighboors[0]][1],
    },
    { x: point[0], y: point[1] }
  );

  if (
    angles.filter(
      angle => angle.polygonId === polygon.id && angle.pointIndex === pointIndex
    ).length > 0
  ) {
    angles[
      angles.findIndex(
        angle =>
          angle.polygonId === polygon.id && angle.pointIndex === point.length
      )
    ] = {
      polygonId: polygon.id,
      pointIndex: pointIndex,
      angle: angle,
    };
  } else {
    angles.push({
      polygonId: polygon.id,
      pointIndex: pointIndex,
      angle: angle,
    });
  }
  if (!returnWithoutCalling)
    pointNeighboors.map(index => {
      getPointNeighboors(
        polygon,
        polygon.points[index],
        index,
        maxIndex,
        angles,
        true
      );
      return index;
    });
  return angles;
}

export const getAllPolygonTransformPoints = polygons => {
  const points = [];

  polygons.map(polygon => {
    polygon.points.map((point, pointIndex) => {
      if (polygon.isMoving[pointIndex] === false)
        points.push({
          polygonId: polygon.id,
          pointIndex: pointIndex,
          point: [point[0], point[1]],
        });
      return point;
    });
    return polygon;
  });
  return points;
};

export const calculateGuidingLines = (
  transformationPoints,
  stageHeight,
  stageWidth,
  imgRealHeigth,
  imgRealWidth,
  cursorCoordinates
) => {
  const guideLines = [];
  const candidateX = [];
  const candidateY = [];

  transformationPoints.forEach(points => {
    const [denormalX, denormalY] = denormalizePoint(
      stageWidth,
      stageHeight,
      points.point
    );
    const realX = Math.round((denormalX * imgRealWidth) / stageWidth);
    const realY = Math.round((denormalY * imgRealHeigth) / stageHeight);
    if (Math.abs(realX - cursorCoordinates.x) <= 10)
      candidateY.push({
        isY: true,
        renderPoint: [denormalX, denormalY],
        distance: Math.abs(realX - cursorCoordinates.x),
        normPoint: points.point,
      });
    if (Math.abs(realY - cursorCoordinates.y) <= 10)
      candidateX.push({
        isY: false,
        renderPoint: [denormalX, denormalY],
        distance: Math.abs(realX - cursorCoordinates.y),
        normPoint: points.point,
      });
  });

  [candidateX, candidateY].forEach(candidates => {
    candidates.sort((a, b) => a.distance - b.distance);
    if (candidates.length > 0) guideLines.push(candidates[0]);
  });

  return guideLines;
};

export const checkIsNearNormPoint = (firstPoint, secondPoint) => {
  const [x1, y1] = firstPoint;
  const [x2, y2] = secondPoint;
  const dist = distanceBetweenPoints(x1, y1, x2, y2);
  if (dist < 0.02)
    // %5 of the stage ratio
    return true;
  return false;
};

export const checkIsPointNearAnyTransformationPoint = (
  point,
  transformationPoints
) => {
  const [x1, y1] = point;
  return transformationPoints.slice(0, -1).some(points => {
    // disclude drawing polygon
    const [x2, y2] = points.point;
    const dist = distanceBetweenPoints(x1, y1, x2, y2);
    if (dist < 0.01) return true;
    return false;
  });
};
