import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { Route } from 'react-router';
// eslint-disable-next-line import/no-cycle
import Logo from './Logo';
import { useAppContext } from '../../AppContext';
import { routeService } from '../../routes/routesList';
import { StyledHeader, StyledSelect } from './styles';
import { FlexContainer } from '../flexContainer';
import { FlexItem } from '../flexItem';
import UserMenu from './Menu';
// eslint-disable-next-line import/no-cycle
import {
  getFullPathArrayForSelect,
  filterCameraSelection,
  modifyCameraSelection,
} from './utils';
import { BodyText } from '../fonts';
import { IconWrapper } from '../iconWrapper';
import { getCorrectPositionValue } from '../selects/utils';
import { formatListData } from '../../utils/formatListData';
import { API_URL } from '../../api/apiURLs';
import apiFetch from '../../api/fetcher';
import WrongCameraDialog from './dialogs/WrongCamera';

export const ALL_CAMERAS_ID = 'all cameras';

const companyTemplate = ({ project_name, id }) => ({
  label: project_name,
  value: id,
});

const Header = () => {
  const [companyOptions, setCompanyOptions] = useState([]);
  const location = useLocation();
  const {
    globalLocation,
    setGlobalLocation,
    userLocations = [],
    isUserLocationsFetching,
    isUserLocationsLoading,
    globalCamera,
    setGlobalCamera,
    userCameras = [],
    isUserCamerasFetching,
    isUserCamerasLoading,
    userInfo,
    setCompany,
    company,
    setActiveNotify,
    cameraOptions,
    setCameraOptions,
    locationOptions,
    setLocationOptions,
  } = useAppContext();

  const {
    list: {
      cameras,
      camera_view_assist,
      camera_view_assist_overlay_tools,
      floorplan_editor,
      camera_validations,
      lens_rectification,
      plane_and_normal,
      detection_polygon,
      aoi_selection,
      floor_projection,
      location_status,
      plane_calibration,
      installation_support,
      vms_settings,
      setbevents,
      setbcameras,
      setbstorage,
      generative_ai,
      live_records,
      setbusersettings,
    },
    getPath,
  } = routeService;

  const allowedPathForLocationSelect = [
    cameras,
    camera_view_assist,
    camera_view_assist_overlay_tools,
    floorplan_editor,
    camera_validations,
    lens_rectification,
    plane_and_normal,
    detection_polygon,
    aoi_selection,
    floor_projection,
    location_status,
    plane_calibration,
    installation_support,
    vms_settings,
    setbevents,
    setbcameras,
    setbstorage,
    generative_ai,
    live_records,
    setbusersettings,
  ];

  const allowedPathForCameraSelect = [
    camera_view_assist,
    installation_support,
    camera_view_assist_overlay_tools,
    lens_rectification,
    plane_and_normal,
    detection_polygon,
    aoi_selection,
    floor_projection,
    plane_calibration,
  ];

  useEffect(() => {
    // check if path is allowed for camera selection
    const isPathAllowed = allowedPathForCameraSelect.some(obj =>
      location.pathname.includes(obj.path)
    );
    if (isPathAllowed) {
      if (userCameras.length > 0) {
        //Reset global camera if switched from multiple camera view assist or store level installation support
        if (
          !location?.pathname.includes(camera_view_assist.path) &&
          globalCamera?.id === ALL_CAMERAS_ID
        )
          setGlobalCamera(null);
        if (
          !location?.pathname.includes(installation_support.path) &&
          globalCamera?.display_name === 'Store level'
        )
          setGlobalCamera(null);
        const selectCamerasOptions = userCameras.map(camera => ({
          label: camera.display_name,
          value: camera.id,
        }));
        const filteredOptions = filterCameraSelection(
          location.pathname,
          selectCamerasOptions,
          globalLocation.display_name
        );
        const filteredAndModifiedOptions = modifyCameraSelection(
          location.pathname,
          filteredOptions
        );
        setCameraOptions(filteredAndModifiedOptions);
      }
    }
  }, [userCameras, location]);

  useEffect(() => {
    if (!isUserLocationsLoading) {
      const selectLocationsOptions = userLocations
        .sort((a, b) => a.display_name.localeCompare(b.display_name))
        .map(location => ({
          label: location.display_name,
          value: location.display_name,
        }));
      setLocationOptions(selectLocationsOptions);
    }
  }, [userLocations]);

  useEffect(() => {
    // Reset camera on location change
    setGlobalCamera(null);
  }, [globalLocation]);

  useEffect(() => {
    const companyOptions = formatListData(
      userInfo?.companies,
      undefined,
      companyTemplate
    );
    setCompanyOptions(companyOptions);
  }, [userInfo]);

  const handleLocationSelectChange = item => {
    setGlobalLocation({
      id: userLocations.find(store => store.display_name === item.label).id,
      display_name: item.value,
    });
  };

  const handleCameraSelectChange = item => {
    setGlobalCamera({
      id: item.value,
      display_name: item.label,
    });
  };

  const handleCompanyChange = ({ value }) => {
    // Reset header when company is changed
    setGlobalCamera(null);
    setGlobalLocation(null);
    setCameraOptions([]);
    setLocationOptions([]);
    apiFetch(API_URL.setCompany(value))
      .then(() => {
        setCompany(userInfo.companies.find(x => x.id === value));
      })
      .catch(error => {
        const errorMessage =
          typeof error?.response?.data?.message === 'string'
            ? error?.response?.data?.message
            : error.message;
        setActiveNotify({
          preset: 'error',
          title: `Failed to change companies`,
          message: errorMessage,
        });
      });
  };

  return (
    <StyledHeader id='ruqnwsxblw'>
      <FlexContainer id='asuucyvwfy' justify='space-between' align='center'>
        <FlexItem id='jcxnlgmbnz'>
          <IconWrapper id='vmbvjyjjaf'>
            <Logo id='rlqhdyitcm' />
          </IconWrapper>
        </FlexItem>
        <FlexItem>
          <FlexContainer align='center'>
            {userInfo?.is_superadmin && (
              <FlexItem style={{ marginRight: '32px' }}>
                <FlexContainer
                  id='stlbcsusfz'
                  gap='4xs'
                  align='center'
                  data-testid='companyLabel'
                >
                  <BodyText id='ifqwocdvzt'>Project:</BodyText>
                  <StyledSelect
                    id='cvhbmhyvik'
                    placeholder='Select company'
                    value={getCorrectPositionValue(
                      companyOptions,
                      company?.id || userInfo?.company?.id
                    )}
                    options={companyOptions}
                    onChange={handleCompanyChange}
                    size='small'
                  />
                </FlexContainer>
              </FlexItem>
            )}
            <Route
              id='mdskbjbzcb'
              path={getFullPathArrayForSelect(
                allowedPathForLocationSelect,
                getPath
              )}
            >
              <FlexItem id='pletouwsrd' style={{ marginRight: '32px' }}>
                <FlexContainer
                  id='lvjbfyipoq'
                  gap='4xs'
                  align='center'
                  data-testid='locationLabel'
                >
                  <BodyText id='rqvcxthzmk'>Location:</BodyText>
                  <StyledSelect
                    id='fyguslzwkz'
                    placeholder='Select location'
                    value={getCorrectPositionValue(locationOptions, {
                      label: globalLocation?.display_name,
                      value: globalLocation?.display_name,
                    })}
                    options={locationOptions}
                    onChange={handleLocationSelectChange}
                    isLoading={
                      isUserLocationsFetching || isUserLocationsLoading
                    }
                    size='small'
                  />
                </FlexContainer>
              </FlexItem>
            </Route>
            <Route
              id='xcdvnywioh'
              path={getFullPathArrayForSelect(
                allowedPathForCameraSelect,
                getPath
              )}
            >
              <FlexItem id='rrqdxwklvc' style={{ marginRight: '32px' }}>
                <FlexContainer
                  id='ogdhkmmkxg'
                  gap='4xs'
                  align='center'
                  data-testid='cameraLabel'
                >
                  <BodyText id='dlqevrpksa'>Camera:</BodyText>
                  <StyledSelect
                    id='ikiqbasgky'
                    placeholder='Select camera'
                    value={getCorrectPositionValue(
                      cameraOptions,
                      globalCamera?.id
                    )}
                    options={cameraOptions}
                    onChange={handleCameraSelectChange}
                    isLoading={isUserCamerasFetching || isUserCamerasLoading}
                    size='small'
                  />
                </FlexContainer>
              </FlexItem>
            </Route>
            <UserMenu id='nalsznjlfy' />
          </FlexContainer>
        </FlexItem>
      </FlexContainer>
      <WrongCameraDialog />
    </StyledHeader>
  );
};

export default Header;
